<template>
    <div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="inputDate"
                    solo
                    dense
                    clearable
                    outlined
                    readonly
                    autocomplete="off"
                    :disabled="disabled"
                    v-model="compDate"
                    hide-details="auto"
                    :error="error"
                    persistent-hint
                    v-bind="attrs"
                    flat
                    prepend-icon="mdi-calendar"                    
                    v-on="on"
                    @click:clear="compDate=null"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="compDate" locale="jp-ja" :day-format="date => new Date(date).getDate()" no-title @input="menu = false"></v-date-picker>
            </v-menu>
    </div>
</template>
<style lang="scss">
.inputfld {
    width: 200px;
}
.v-date-picker-table {
    table,
    th,
    tr,
    td {
        border: 0px none !important;
    }
}
.msg {
    width: 400px;
}
.inputDate {
    width: 180px;
}
.inputDate fieldset{
    background: #fff !important;

}
</style>

<script>
import moment from "moment"
export default {
    props: {
        date: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        disabled:{
            type:Boolean,
            default:false
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        compDate: {
            get() {
                if(this.date && moment(this.date).isValid){
                    return this.date.substr(0,10);
                }
                else{
                    return null;
                }
            },
            set(value) {
                this.$emit("update:date", value);
            },
        },
    },
    methods: {
        clickOk() {
            this.$refs.menu.save(this.compDate);
        },
    },
    data: () => ({
        pDate: null,
        menu: false,
        modal: false,
        menu2: false,
    }),
};
</script>