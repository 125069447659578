<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-account</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員照会
                </v-card-subtitle>
                <v-spacer />
                <v-btn class="ma-2 btn-close" @click="clickBack()">戻る</v-btn>
            </v-system-bar>
        </v-card>
        <v-subheader class="text-h6 font-weight-black mt-3 mb-0 mx-5"
            >会員基本情報
            <v-spacer />
            <v-btn class="btn-edit" @click="clickEdit()">会員情報を編集する</v-btn>
        </v-subheader>
        <v-divider class="mb-5 mx-5"></v-divider>
        <v-card class="mx-5 py-8">
            <v-row class="mx-4" >
                <v-col cols="1" class="font-weight-bold">支部</v-col>
                <v-col>：{{ memberlist.shibu_name }}</v-col>
            </v-row>
            <v-row class="mx-4">
                <v-col cols="1" class="font-weight-bold">会員区分</v-col>
                <v-col>：{{ getKbnName(memberlist.member_kbn) }}</v-col>
            </v-row>
            <v-row class="mx-4">
                <v-col cols="1" class="font-weight-bold">会員CD</v-col>
                <v-col>：{{ memberlist.member_cd }}</v-col>
            </v-row>
            <v-row class="mx-4">
                <v-col cols="1" class="font-weight-bold">会員名</v-col>
                <v-col>：{{ memberlist.member_name }}　</v-col>
            </v-row>
            <v-row class="mx-4">
                <v-col cols="1" class="font-weight-bold">住所</v-col>
                <v-col cols="8">
                    <div>：〒{{ memberlist.post }} {{ memberlist.address3 }}</div>
                    <div class="d-flex d-inline-flex">
                    <div  style="width: 220px">　<span class="text-caption">(TEL)</span> {{ memberlist.tel }}</div>
                    <div  style="width: 220px">　<span class="text-caption">(FAX)</span> {{ memberlist.fax }}</div>
                    </div>
                    <div class="d-flex d-inline-flex">
                    <div style="width: 250px">　<span class="text-caption">(mail)</span> {{ memberlist.mail }}</div>
                    <div><span class="text-caption">(担当) </span>{{ memberlist.tantou_name }}</div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-subheader class="text-h6 font-weight-black mt-3 mb-0 mx-5">
            プラント情報
        </v-subheader>
        <v-divider class="mb-5 mx-5"></v-divider>
        <v-simple-table fixed-header height="auto" class="datatable mb-1 mx-5" dense>
            <template v-slot:default>
                <col style="width: 8%" />
                <col style="width: auto" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 10%" />
                <thead>
                    <tr>
                        <th>プラントCD</th>
                        <th>プラント名</th>
                        <th>最終更新日</th>
                        <th align="right">原料土受入可能量</th>
                        <th align="right">改良土ストック量</th>
                        <th>処理</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in plantlist" :key="item.member_no">
                        <td class="text-center">{{  zeroPadding(item.plant_num, 3) }}</td>
                        <td class="text-left">{{ item.plant_name }}</td>
                        <td class="text-left">{{ item.update_at }}</td>
                        <td align="right">{{ numberFormat( item.now_gendo_uke) }}&nbsp;m3</td>
                        <td align="right">{{ numberFormat( item.now_kaido_stock) }}&nbsp;m3</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="btn-edit ml-1 mr-1"
                                @click="clickPlant(item.plant_id)"
                                >詳しく見る</v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center">
            <v-pagination
                v-model="search.page"
                circle
                :length="pageCnt"
                :total-visible="20"
                @input="getPlantList()"
            ></v-pagination>
        </div>
    </v-container>
</template>
<style scope>
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px 16px !important;
}
.v-input{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
</style>
<script>
import { MESSAGE } from "../messages";
export default {
    name: "Member",
    components: {},
    data() {
        return {
            memberlist: [],
            plantlist: [],
            pageCnt: 0,
            search: ({} = {
                memberId: "",
                page: 1,
                perPage: 10,
            }),
        };
    },
    mounted() {
        if (history.state == "entry") {
            if (sessionStorage.getItem('meminqury-data') !== null) {
                this.$data['search'] = JSON.parse(sessionStorage.getItem('meminqury-data'));
            }
        }
        this.search.memberId = this.$store.state.memberId;
        this.getMemberList();
        this.getPlantList();
        history.replaceState("entry", null);
    },
    methods: {
        save_storage() {
            sessionStorage.setItem('meminqury-data', JSON.stringify(this.$data['search']));
        }, 
        getMemberList() {
            this.axios.get(`/member/${this.search.memberId}`).then((res) => {
                this.memberlist = res.data.resultData.datalist;
            });
        },
        getPlantList() {
            this.axios.post(`/member-search-plant`, this.search).then((res) => {
                this.plantlist = res.data.resultData.plantlist;
                this.pageCnt = res.data.resultData.pageCnt;
            });
        },
        clickEdit() {
            this.save_storage();

            this.$store.commit('setMemberId', this.search.memberId);
            this.$router.push({name: "MemberEdit", params: { mode: 'u', memberId: this.search.memberId}});
        },
        clickPlant(plantId){
            this.save_storage();
            this.$store.commit('setPlantId', plantId);
            this.$router.push({name: "PlantView", params: { type: 'Member' }});
        },
        clickBack() {
            if(this.$route.params.hasOwnProperty('type')){
                this.$router.push({ name: 'MemberList' })
            }else{
                this.$router.push({ name: 'MemberList', params: { type: 'MemberInqury' } });
            }
        },
    },
};
</script>

