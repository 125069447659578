import Vue from 'vue'
import VueRouter from 'vue-router'
import MemberApplyList from '../views/MemberApplyList.vue'
import MemberApproval from '../views/MemberApproval.vue'
import MemberList from '../views/MemberList.vue'
import MemberInqury from '../views/MemberInqury.vue'
import MemberEdit from '../views/MemberEdit.vue'
import MemberNew from '../views/MemberNew.vue'
import Information from '../views/Information.vue'
import InformationEdit from '../views/InformationEdit.vue'
import PlantView from '../views/PlantView.vue'
import PlantEdit from '../views/PlantEdit.vue'
import PlantNew from '../views/PlantNew.vue'
import PlantList from '../views/PlantList.vue'
import PlantMap from '../views/PlantMap.vue'
import Dashboard from '../views/Dashboard.vue'
import MobileTest from '../views/MobileTest.vue'
import TdfkShibuMst from '../views/TdfkShibuMst.vue'
import Login from '../views/Login.vue'
import UpdateLimit from '../views/UpdateLimit.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login 
  },
  /* ダッシュボード */
  {
    /*プラント表示 */
    path: '/',
    name: 'Top',
    component: Dashboard 
  },
  {
    /*プラント表示 */
    path: '/plant',
    name: 'PlantList',
    component: PlantList 
  },
  {
    /*プラント照会 */
    path: '/plant-view',
    name: 'PlantView',
    component: PlantView 
  },
  {
    /*プラント照会 */
    path: '/plant-edit',
    name: 'PlantEdit',
    component: PlantEdit 
  },
  {
    /*プラント登録*/
    path: '/plant-new',
    name: 'PlantNew',
    component: PlantNew 
  },
  {
    path: '/member-applylist',
    name: 'MemberApplyList',
    component: MemberApplyList 
  },
  {
    path: '/member-approval',
    name: 'MemberApproval',
    component: MemberApproval
  },
  {
    path: '/member',
    name: 'MemberList',
    component: MemberList 
  },
  {
    path: '/member-inqury',
    name: 'MemberInqury',
    component: MemberInqury
  },
  {
    path: '/member-new',
    name: 'MemberNew',
    component: MemberNew 
  },
  {
    path: '/member-edit',
    name: 'MemberEdit',
    component: MemberEdit
  },
  {
    path: '/information',
    name: 'Information',
    component: Information
  },
  {
    path: '/information-edit',
    name: 'InformationEdit',
    component: InformationEdit
  },
  {
    path: '/plantmap',
    name: 'PlantMap',
    component: PlantMap
  },
  {
    path: '/mobile',
    name: 'MobileTest',
    component: MobileTest
  },
  {
    path: '/updatelimit',
    name: 'UpdateLimt',
    component: UpdateLimit 
  },
  /**都道府県・支部マスタ編集 */
  {
    path:'/tdfk-shibu-mst',
    name: 'TdfkShibuMst',
    component: TdfkShibuMst
  },
	 {path: '/maintenance', name:'maintenance', component:()=>import('../views/Maintenance.vue')},
	 {path: '/adminlist', name:'adminlist', component:()=>import('../views/AdminList.vue')},
	 {path: '/adminedit', name:'adminedit', component:()=>import('../views/AdminEdit.vue')},
	 {path: '/maillist', name:'maillist', component:()=>import('../views/MailList.vue')},
	 {path: '/mailedit', name:'mailedit', component:()=>import('../views/MailEdit.vue')},
	 {path: '/siryo', name:'siryoedit', component:()=>import('../views/SiryoEdit.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from , next)=>{
  if(to.name ==='Login' && store.state.token != ''){
    next({name: 'MemberList'})
  }else{
    if((to.name !== 'Login'  )&& store.state.token == '') next({name:'Login'})
    else next()

  }
})

export default router
