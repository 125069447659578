<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
            <v-sheet
                elevation="2"
				rounded="0"
                height="40"
				max-width="100%"
                class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
            >
                <v-icon class="white--text mb-2">mdi-database</v-icon>
                プラント管理
            </v-sheet>
            <v-card class="ma-5 rounded" >
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                   <div v-if="plantinfo.plant_id==0"> 
                    プラント情報登録
                   </div>
                   <div v-if="plantinfo.plant_id!=''"> 
                    プラント情報更新
                   </div>
                    </v-card-subtitle>
                    <v-spacer />
                <v-btn
                    :loading="saveLoading"
                    :disabled="saveLoading"
                    class="btn-edit ma-2"
                    @click="clickSave"
                    >保存</v-btn
                >
                <v-btn class="btn-close ma-2" @click="clickBack"
                    >もどる</v-btn
                >
                </v-system-bar>
            </v-card>
	</v-card>



        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員情報
                </v-card-subtitle>
                <v-spacer />
                <v-btn @click="expand = !expand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>

            <v-expand-transition>
                <div v-show="expand" class="pa-5">
                    <table class="plant-tbl" dense>
                        <col width="150" />
                        <col width="200" />
                        <col />
                        <tr>
                            <th>会員CD<span class="required">(必須)</span></th>
                            <td>  

                                <v-text-field
                                    style="width: 100px"
                                    class="mr-3"
                                    v-model="plantinfo.member_cd"
                                    :error="isError(err.member_id)"
                                    solo
                                    readonly
                                    dense
                                    outlined
                                    hide-details=''
                                />

                            </td>
                            <td>
                                <div v-show="!plantinfo.plant_id">
                                    <dlg-member
                                        :btn-title="btnMemberTitle"
                                        :member-cd.sync="plantinfo.member_cd"
                                        :member-id.sync="plantinfo.member_id"
                                        :member-name.sync="plantinfo.member_name"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.member_id)"
                                    >
                                        <span v-html="dispError(err.member_id)"></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>会員名</th>
                            <td colspan="2">{{ plantinfo.member_name }}</td>
                        </tr>
                    </table>
                </div>
            </v-expand-transition>
        </v-card>

        <v-card class="mx-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    プラント情報
                </v-card-subtitle>
            </v-system-bar>
            <div class="pa-5">
                <table class="plant-tbl">
                    <colgroup>
                        <col width="150" />
                        <col width="150" />
                        <col width="150" />
                        <col width='' />
                    </colgroup>
                    <tr>
                        <th>プラント名
                            <span class="required">(必須)</span>
                            </th>
                        <td colspan="3">
                            <v-text-field
                                maxlength="20"
                                :error="isError(err.plant_name)"
                                v-model="plantinfo.plant_name"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.plant_name)"
                        >
                            <span v-html="dispError(err.plant_name)"></span>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <th>郵便番号<span class="required">(必須)</span></th>
                        <td colspan="3">
                            <div class="d-inline-flex">
                                <v-text-field
                                    style="width: 100px"
                                    class="mr-3"
                                    v-model="plantinfo.post"
                                    :error="isError(err.post)"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                    @blur="blurPost"
                                />
                                <v-btn
                                    depressed
                                    elevation="2"
                                    height="32"
                                    color="primary"
                                    @click="clickPost"
                                    >郵便番号から住所に変換</v-btn
                                >
                            </div>
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.post)"
                        >
                            <span v-html="dispError(err.post)"></span>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="3">住所・連絡先</th>
                        <td class="td-title">都道府県<span class="required">(必須)</span></td>
                        <td colspan="2">
                            <div style="width: 150px">
                                <v-select
                                    v-model="plantinfo.tdfk_cd"
                                    dense
                                    outlined
                                    hide-details=''
                                    :error="isError(err.tdfk_cd)"
                                    height="32"
                                    :items="tdfk_list"
                                    item-text="tdfk_name"
                                    item-value="tdfk_cd"
                                ></v-select>
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.tdfk_cd)"
                            >
                                <span v-html="dispError(err.tdfk_cd)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title">市区町村<span class="required">(必須)</span></td>
                        <td colspan="2">
                            <v-text-field
                                maxlength="50"
                                v-model="plantinfo.address1"
                                :error="isError(err.address1)"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.address1)"
                            >
                                <span v-html="dispError(err.address1)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title">丁目番地</td>
                        <td colspan="2">
                            <v-text-field
                                maxlength="50"
                                v-model="plantinfo.address2"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>緯度経度<span class="required">(必須)</span></th>
                        <td colspan="3">
                            <div class="d-inline-flex">
                                <div style="width: 50px" class="tet-center">
                                    緯度
                                </div>
                                <div style="width: 100px">
                                    <v-text-field
                                        maxlength="12"
                                        v-model="plantinfo.lat"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.lat)"
                                        hide-details=''
                                    />
                                </div>
                                <div
                                    class="ml-3 plant-text-center"
                                    style="width: 50px"
                                >
                                    経度
                                </div>
                                <div style="width: 100px">
                                    <v-text-field
                                        maxlength="12"
                                        v-model="plantinfo.lng"
                                        :error="isError(err.lng)"
                                        solo
                                        dense
                                        outlined
                                        hide-details=''
                                    />
                                </div>
                                <div class="ml-3">
                                    <v-btn
                                        depressed
                                        elevation="2"
                                        height="32"
                                        color="primary"
                                        @click="addressToLatlng"
                                        >住所から変換</v-btn
                                    >
                                </div>
                                <div class="ml-3">
                                    <v-btn
                                        depressed
                                        elevation="2"
                                        height="32"
                                        color="primary"
                                        @click="clickMap"
                                        >マップ表示</v-btn
                                    >
                                </div>
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.lat)"
                            >
                                <span v-html="dispError(err.lat)"></span>
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.lng)"
                            >
                                <span v-html="dispError(err.lng)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>プラント認証番号</th>
                        <td colspan="3">
                            <div class="d-inline-flex txt-center">
                                    <span class="mr-2">(ACTEC)</span>
                                    <div style="width:140px">
                                    <v-text-field
                                        maxlength="10"
                                        type="tel"
                                        v-model="plantinfo.actec_no"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.actec_no)"
                                        hide-details=''
                                    />
                                    </div>
                                    <span class="mx-2">(自治体)</span>
                                    <div style="width:140px">
                                    <v-text-field
                                        maxlength="10"
                                        type="tel"
                                        v-model="plantinfo.actec_no_pre"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.actec_no_pre)"
                                        hide-details=''
                                    />
                                    </div>
                            </div>

                        </td>
                    </tr>

                    <tr>
                        <th>電話番号</th>
                        <td colspan="3">
                            <div class="d-inline-flex txt-center">
                                    <span class="mr-2">(電話)</span>
                                    <div style="width:140px">
                                    <v-text-field
                                        maxlength="15"
                                        type="tel"
                                        v-model="plantinfo.tel"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.tel)"
                                        hide-details=''
                                    />
                                    </div>
                                    <span class="mx-2">(FAX)</span>
                                    <div style="width:140px">
                                    <v-text-field
                                        maxlength="15"
                                        type="tel"
                                        v-model="plantinfo.fax"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.fax)"
                                        hide-details=''
                                    />
                                    </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <th>担当者名</th>
                        <td colspan="3">
                            <div class="d-inline-flex txt-center">
                                    <div style="width:240px">
                                    <v-text-field
                                        maxlength="10"
                                        v-model="plantinfo.tantou_name"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.tantou_name)"
                                        hide-details=''
                                    />
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>メールアドレス</th>
                        <td colspan="3">
                            <div class="d-inline-flex txt-center">
                                    <div style="width:240px">
                                    <v-text-field
                                        maxlength="80"
                                        type="tel"
                                        v-model="plantinfo.mail"
                                        solo
                                        dense
                                        outlined
                                        :error="isError(err.mail)"
                                        hide-details=''
                                    />
                                    </div>
                            </div>
                        </td>
                    </tr>


                    <tr>
                        <th>ホームページ</th>
                        <td colspan="3">
                            <div style="width: 500px">
                                <v-text-field
                                    maxlength="500"
                                    type="tel"
                                    v-model="plantinfo.homepage"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th>営業日</th>
                        <td colspan="3">
                            <div style="width: 500px">
                                <v-text-field
                                    maxlength="20"
                                    v-model="plantinfo.sales_day"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>営業時間</th>
                        <td colspan="3">
                            <div style="width: 500px">
                                <v-text-field
                                    maxlength="20"
                                    v-model="plantinfo.sales_time"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>稼働年月</th>
                        <td colspan="3">
                                <div class="d-inline-flex txt-center">
                                    <div style="width:80px" class="mr-2">
                                        <v-text-field
                                            maxlength="4"
                                            v-model="plantinfo.kado_y"
                                            solo
                                            :error="isError(err.kado_y)"
                                            dense
                                            outlined
                                            hide-details=''
                                        />
                                    </div>
                                        年
                                        <div style="width:80px" class="mx-2">
                                            <v-select v-model="plantinfo.kado_m" 
                                            hide-details=''
                                            height="32"
                                            :error="isError(err.kado_m)"
                                            :items="mon_list" dense outlined >
                                            </v-select>
                                        </div>月
                                </div>
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.kado_y)"
                                >
                                    <span v-html="dispError(err.kado_y)"></span>
                                </div>
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.kado_m)"
                                >
                                    <span v-html="dispError(err.kado_m)"></span>
                                </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title" align="center">敷地面積</td>
                        <td colspan="3">
                            <div
                                style="width: 120px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.site_area"
                                    class="mr-3"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m2
                        </td>
                    </tr>
                    <tr>
                        <th>原料土ヤード</th>
                        <td>
                            <div
                                style="width: 100px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.mate_area"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m2
                        </td>
                        <td class="td-title">最大ストック量</td>
                        <td style="width: 200px">
                            <div
                                style="width: 120px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    class="mr-3"
                                    v-model="plantinfo.mate_stock"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m3
                        </td>
                    </tr>
                    <tr>
                        <th>改良土ヤード</th>
                        <td>
                            <div
                                style="width: 100px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.impsoil_area"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m2
                        </td>
                        <td class="td-title">最大ストック量</td>
                        <td style="width: 200px">
                            <div
                                style="width: 120px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    class="mr-3"
                                    v-model="plantinfo.impsoil_stock"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m3
                        </td>
                    </tr>
                    <tr>
                        <th>土質改良能力</th>
                        <td colspan="3">
                            <div
                                style="width: 100px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.impsoil_ability_h"
                                    class="mr-0"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m3/時
                            <div
                                style="width: 100px"
                                class="plant-text-center d-inline-flex ml-5"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.impsoil_ability_d"
                                    class="ml-3"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            m3/日
                            <div
                                style="width: 100px"
                                class="plant-text-center d-inline-flex"
                            >
                                <v-text-field
                                    maxlength="8"
                                    type="number"
                                    v-model="plantinfo.impsoil_ability_y"
                                    class="ml-3"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            万m3/年
                        </td>
                    </tr>
                    <tr>
                        <th>改良土の種類</th>
                        <td colspan="3">
                            <div class="d-inline-flex">
                                <div v-for="(item, i) in kairyo_zai" :key="i">
                                <v-checkbox
                                    class="mr-7"
                                    :label="item.kubun_value1"
                                    :value="item.kubun_cd"
                                    v-model="plantinfo.chk_kairyo_zai"
                                    dense
                                    hide-details=''
                                >
                                </v-checkbox>
                                </div>
                                <div>

                            <div
                                style="width: 200px"
                                class="txt-center d-inline-flex"
                            >
                           <span> （</span>
                           <div style="width:200px">
                                <v-text-field
                                    maxlength="6"
                                    v-model="plantinfo.ukeire_zai_sonota"
                                    class="ml-3"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                    :disabled="check_sonota"
                                /></div>
                                <span class="mr-2">　）</span>
                            </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>受入土質</th>
                        <td colspan="3">
                            <div class="d-inline-flex">
                                <div v-for="(item, i) in ukeire_do" :key="i">
                                <v-checkbox
                                    class="mr-7"
                                    :label="item.kubun_value1"
                                    :value="item.kubun_cd"
                                    v-model="plantinfo.chk_ukeire_do"
                                    dense
                                    hide-details=''
                                >
                                </v-checkbox>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>その他受入条件</th>
                        <td colspan="3">
                            <v-text-field
                                maxlength="20"
                                v-model="plantinfo.gendo_uke_jyoken"
                                class="ml-3"
                                :error="isError(plantinfo.gendo_uke_jyoken)"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                </table>
            </div>

            <div class="pa-5">
                <div class="plant-subtitle">料金情報</div>
                <table class="plant-tbl">
                    <colgroup>
                        <col width="150" />
                        <col width="400" />
                        <col width='' />
                    </colgroup>
                    <tr>
                        <th></th>
                        <td style="background-color: #555; color: white">
                            HP　一覧掲載品目
                        </td>
                        <td style="background-color: #555; color: white">
                            料金
                        </td>
                    </tr>
                    <tr>
                        <th>原料土</th>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.hp_gendo_uke_name"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                        <td class="d-flex align-center">
                            <div style="width: 90px">
                                <v-text-field
                                    type="number"
                                    class="pr-3"
                                    maxlength="6"
                                    v-model="plantinfo.hp_gendo_uke_kingaku"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            円/地山 m3
                        </td>
                    </tr>
                    <tr>
                        <th>改良土</th>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.hp_kaido_name"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                        <td class="d-flex align-center">
                            <div style="width: 90px">
                                <v-text-field
                                    maxlength="6"
                                    type="number"
                                    class="mr-3"
                                    v-model="plantinfo.hp_kaido_kingaku"
                                    solo
                                    dense
                                    outlined
                                    hide-details=''
                                />
                            </div>
                            円/ほぐし m3
                        </td>
                    </tr>
                    <tr>
                        <th>PDF料金表</th>
                        <td colspan="2">
                            <div class="">
                            <v-radio-group class="ml-2" v-model="plantinfo.pdf_flg" hide-details="auto" row>
                                <v-radio
                                        v-for="item in pdfTypeList"
                                        :key="item.no"
                                        :value="item.no"
                                        class="mr-7"
                                        :label="item.val"
                                        dense
                                        hide-details=""
                                />
                                </v-radio-group>
                            </div>
                            <div class="my-4">
                                <v-row class="align-center">
                                    <v-col cols="2">料金表</v-col>
                                    <v-col cols="10">
                                        <input style="display:none" type="file" id="file" ref="file" @change="fileChange" />
                                        <v-btn class="btn-edit" @click="clickFileUpload">アップロード</v-btn>
                                        <div v-show="uploadFileName!=''" class="pl-5">
                                            {{uploadFileName}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="10">
                                        <ryokin-file 
                                        ignore_flg=true
                                        title="料金表をダウンロードする"
                                        not_title="料金表はアップロードされていません"
                                        :plant_id="plantinfo.plant_id" />
                                    </v-col>
                                </v-row>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>申込み</th>
                        <td colspan="2">
                            <div class="mt-1">
                                <v-radio-group
                                v-model="plantinfo.moshikomi_kbn"
                                row
                                >
                                    <v-radio
                                        v-for="item in moshikomiTypeList"
                                        :key="item.no"
                                        :label="item.val"
                                        :value="item.no"
                                    ></v-radio>
                                </v-radio-group>
                            </div>
                            <div class="mb-2">
                                <v-row class="align-center">
                                    <v-col cols="2">申込みURL</v-col>
                                    <v-col cols="10">
                                        <v-text-field
                                            maxlength="100"
                                            type="tel"
                                            v-model="plantinfo.moshikomi_url"
                                            :error="isError(err.moshikomi_url)"
                                            solo
                                            dense
                                            outlined
                                            hide-details=''
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="align-center">
                                    <v-col cols="2">申込み用紙</v-col>
                                    <v-col cols="3">
                                        <input style="display:none" type="file" id="mfile" ref="mfile" @change="moshikomiChange" />
                                        <v-btn class="btn-edit" @click="clickMoshikomiUpload">アップロード</v-btn>
                                    </v-col>
                                    <v-col cols="5">
                                        {{uploadMoshikomiName}}
                                    </v-col>
                                </v-row>
                                <v-row class="align-center">
                                    <v-col cols="2"></v-col>
                                    <v-col cols="7">
                                        <moshikomi-file 
                                        ignore_flg="true"
                                        title="申込み用紙をダウンロードする"
                                        not_title="申込み用紙はアップロードされていません"
                                        :plant_id="plantinfo.plant_id" />
                                    </v-col>
                                </v-row>
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.moshikomi_url)"
                            >
                                <span v-html="dispError(err.moshikomi_url)"></span>
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.moshikomi_pdf)"
                            >
                                <span v-html="dispError(err.moshikomi_pdf)"></span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="pa-5">
                <div class="plant-subtitle">改良土品質管理基準（試験頻度）</div>
                <table class="plant-tbl">
                    <colgroup>
                        <col width="25" />
                        <col width='' />
                        <col width='' />
                    </colgroup>

                    <tr>
                        <th rowspan="3">力学的指数</th>
                        <td class="td-title">CBR</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_cbr"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title">一軸圧縮強度</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_itijiku"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title">コーン指数</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_cone"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="2">粒径</th>
                        <td class="td-title">最大粒径</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_ryukei"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title">細粒分</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_sairyu"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title" colspan="2">含水比</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_gansui"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title" colspan="2">pH</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_ph"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title" colspan="2">土壌環境基準</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_dojyou"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td-title" colspan="2">その他</td>
                        <td>
                            <v-text-field
                                maxlength="20"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.manage_etc"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <div class="pa-5">
                <div class="plant-subtitle">保有重機と台数</div>

                <v-simple-table class="plant-tbl">
                    <tr>
                        <td>
                            <v-text-field
                                maxlength="50"
                                class="ml-3"
                                style="width: 360px"
                                v-model="plantinfo.jyuuki_daisuu"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />

                        </td>
                    </tr>
                </v-simple-table>
            </div>
            <div class="pa-5">
                <div class="plant-subtitle">建設発生土実績・改良土出荷実績</div>

                <table class="plant-tbl">
                    <col width="40">
                    <col>
                    <col>
                    <col width="80">
                    <tr>
                        <th>年度</th>
                        <td class="td-title">発生土受入量　万地山m3</td>
                        <td class="td-title">改良土出荷量　万ほぐしm3</td>
                        <td class="td-title">編集</td>
                    </tr>
                    <tr v-for="(item, i) in plantinfo.jituseki" :key="i">
                        <td>{{item.nendo}}</td>
                        <td>{{item.hassei_gendo}}m3</td>
                        <td>{{item.shuka_kairyou}}m3</td>
                        <td>
                            <v-btn @click="modOpen(item.nendo,item.hassei_gendo, item.shuka_kairyou)"
                            depressed
                            elevation="2"
                            height="32"
                            class="btn-edit ma-1"
                            >
                                編集
                            </v-btn>

                            <v-btn
                                depressed
                                elevation="2"
                                class="btn-delete ma-1"
                                height="32"
                                @click="delJituseki(item)"
                                >削除</v-btn
                            >
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"></td>
                        <td>
                            <v-btn @click="addOpen"
                            depressed
                            elevation="2"
                            class="btn-new ma-1"
                            height="32"
                            >
                               追加 
                            </v-btn>


                        </td>
                    </tr>
                </table>
            </div>
        </v-card>
        <dlg-jituseki ref="refJItusekiDialog" :callback="upJituseki" />
        <dlg-map ref="refDlgMap" :dlg-call-back="callBackMap" />
    </v-container>
</template>
<style lang="scss" scoped>
.plant-tbl {
    width: 800px;
}
.w-20 {
    width: 100px;
}
.w-6 {
    width: 30px !important;
}
.plant-subtitle {
    height: 30px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    font-weight: bold;
}
.plant-tbl p {
    margin: 0px;
}
$text-field-line-height: 10px;
$text-field-border-radius: 20px;
.col-title {
    border: 1px solid #ccc;
    width: 200px;
    display: flex;
    align-items: center;
}
.col-content {
    border: 1px solid #ccc;
}
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
import DlgMember from "../components/dialog/DlgMember.vue";
import DlgJituseki from "../components/dialog/DlgJituseki.vue";
import DlgMap from "../components/dialog/DlgMap.vue"
import RyokinFile from "../components/RyokinFile.vue"
import MoshikomiFile from "../components/MoshikomiFile.vue"
import {CONSTANT} from "../constant";
export default {
    name: "PlantEdit",
    components: { DlgMember, DlgJituseki,DlgMap, RyokinFile, MoshikomiFile },
    data() {
        return {
            expand: true,
            tdfk_list: [],
            sel_tdfk: '',
            saveLoading: false,
            btnMemberTitle: "会員を選択する",

            //チェックボックス
            ukeire_do:[],
            kairyo_zai:[],

            //PDFファイルアップロード用
            /*料金表*/
            pdf_file:'',
            uploadFileName:'',
            /*申し込み*/
            moshikomi_file:'',
            uploadMoshikomiName:'',
            moshikomiTypeList: [
                { no: 1, val: 'ＵＲＬ表示'},
                { no: 2, val: 'ＰＤＦ表示'},
                { no: 3, val: '表示しない'},
            ],
            pdfTypeList: [
                { no: 1, val: 'ＰＤＦ表示'},
                { no: 0, val: '表示しない'},
            ],

            mon_list:['','01','02','03','04','05','06','07','08','09','10','11','12'],

            //test
            plant_id_test:'',

            //プラント
            plantinfo: {
                plant_id:  0,
                member_id: 0,
                member_cd: '',
                member_name: '',
                plant_num: '',
                plant_name: '',
                plant_name_kana: '',
                status,
                sales_day: '',
                sales_time: '',
                actec_no: '',
                actec_no_pre: '',
                post: '',
                tdfk_name: '',
                tdfk_cd:'',
                address1: '',
                address2: '',
                address3: '',
                lat: '',
                lng: '',
                area_cd: '',
                shibu_cd: '',
                tel: '',
                fax: '',
                mail: '',
                tantou_name: '',
                kado_ym: '',
                kado_y:'',
                kado_m:'',
                site_area: '',
                mate_area: '',
                mate_stock: '',
                impsoil_area: '',
                impsoil_stock: '',
                impsoil_ability_h: '',
                impsoil_ability_d: '',
                impsoil_ability_y: '',
                ukeire_zai_sonota: '',
                gendo_uke_jyoken: '',
                hp_gendo_uke_name: '',
                hp_gendo_uke_kingaku: '',
                hp_kaido_name: '',
                hp_kaido_kingaku: '',
                pdf_flg: '',
                pdf_path: '',
                manage_cbr: '',
                manage_itijiku: '',
                manage_cone: '',
                manage_ryukei: '',
                manage_sairyu: '',
                manage_gansui: '',
                manage_ph: '',
                manage_dojyou: '',
                manage_etc: '',
                jyuuki_daisuu: '',
                now_gendo_uke: '',
                now_kaido_stock: '',
                last_upddate: '',
                comment: '',
                moshikomi_kbn: 3,
                moshikomi_url: '',
                moshikomi_pdf: '',
                homepage:'',
                chk_ukeire_do:[],
                chk_kairyo_zai:[],
                jituseki:[],
            },
            err: {
                plant_id: '',
                member_id: 0,
                member_cd: '',
                member_name: '',
                plant_num: '',
                plant_name: '',
                plant_name_kana: '',
                status,
                sales_day: '',
                sales_time: '',
                actec_no: '',
                actec_no_pre: '',
                post: '',
                tdfk_name: '',
                tdfk_cd:'',
                address1: '',
                address2: '',
                address3: '',
                lat: '',
                lng: '',
                area_cd: '',
                shibu_cd: '',
                tel: '',
                fax: '',
                mail: '',
                tantou_name: '',
                kado_ym: '',
                kado_y:'',
                kado_m:'',
                site_area: '',
                mate_area: '',
                mate_stock: '',
                impsoil_area: '',
                impsoil_stock: '',
                impsoil_ability_h: '',
                impsoil_ability_d: '',
                impsoil_ability_y: '',
                ukeire_zai_sonota: '',
                gendo_uke_jyoken: '',
                hp_gendo_uke_name: '',
                hp_gendo_uke_kingaku: '',
                hp_kaido_name: '',
                hp_kaido_kingaku: '',
                pdf_flg: '',
                pdf_path: '',
                manage_cbr: '',
                manage_itijiku: '',
                manage_cone: '',
                manage_ryukei: '',
                manage_sairyu: '',
                manage_gansui: '',
                manage_ph: '',
                manage_dojyou: '',
                manage_etc: '',
                jyuuki_daisuu: '',
                now_gendo_uke: '',
                now_kaido_stock: '',
                last_upddate: '',
                comment: '',
                moshikomi_kbn: null,
                moshikomi_url: '',
                moshikomi_pdf: '',
                homepage:'',
                chk_ukeire_do:'',
                chk_kairyo_zai:'',
                jituseki:'',
            },

            //ここから削除
            test: '',
            kadoCnt: 0,
            newCnt: 0,
            systemInfo: {},
            statusFlg: null,
        };
    },
    computed:{
        ryokin_hyo_flg:{
            get(){
                if(this.plantinfo.pdf_flg=='1')
                    return true;
                else   
                    return false;
            },
            set(value){
                if(value)
                    this.plantinfo.pdf_flg = '1';
                else   
                    this.plantinfo.pdf_flg = '0';
            }
        },
        check_sonota:{
            /**
             * 改良材でその他が選択されているか？
             * その他は最後の区分なのでえ、最後が選択状態であれば false を返す
             */
            get(){
                if(!this.kairyo_zai.length){
                    return true;
                }
                const val = this.kairyo_zai[this.kairyo_zai.length-1].kubun_cd;
                if(this.plantinfo.chk_kairyo_zai.includes(val)){
                    return false;
                }
                return true;
            }
        }

    },
    mounted() {
        this.getInit();

        this.plantinfo.plant_id = this.$store.state.plantId;

        if (this.plantinfo.plant_id !== 0) {

            this.getPlantInfo(this.plantinfo.plant_id);
        }
    },
    methods: {
        /**
         *　画面表示に必要なデータを取得
         */
        async getInit() {
            await this.axios.post("/plant-edit-init").then((res) => {
                if (res.data.resultCode == 0) {
                    this.tdfk_list = res.data.resultData.tdfk;
                    this.ukeire_do = res.data.resultData.ukeire_do;
                    this.kairyo_zai = res.data.resultData.kairyo_zai;
                }
            });
        },
        /**
         * 実績ダイアログ（編集）オープン
         */
        modOpen(nendo,hassei_gendo,shuka_kairyou){
            this.$refs.refJItusekiDialog.open('UP',this.plantinfo.jituseki ,nendo,hassei_gendo,shuka_kairyou)
        },

        /**
         * 実績ダイアログ（追加）オープン
         */
        addOpen(){
            this.$refs.refJItusekiDialog.open('ADD',this.plantinfo.jituseki);
        },

        /**
         * 実績の削除
         */
        async delJituseki(item){

            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,MESSAGE.formatByArr(MESSAGE.R003,item.nendo+"年度の実績"));
            if(!res){
                this.saveLoading = null;
                return ;
            }

            this.plantinfo.jituseki = this.plantinfo.jituseki.filter((p)=>{
                return (p.nendo !== item.nendo);
            });
        },

        /**
         * 実績ダイアログからのコールバック
         */
        upJituseki(nendo,hassei_gendo,shuka_kairyou){

            var upd=false;
            for(let i in this.plantinfo.jituseki){
                if(this.plantinfo.jituseki[i].nendo==nendo){
                    this.plantinfo.jituseki[i].hassei_gendo = hassei_gendo;
                    this.plantinfo.jituseki[i].shuka_kairyou = shuka_kairyou;
                    upd = true;
                    return;
                }
            }
            if(!upd){
                this.plantinfo.jituseki.push({nendo:nendo,hassei_gendo:hassei_gendo,shuka_kairyou:shuka_kairyou});
            }
        },
        async clicktest(){
            this.getPlantInfo(this.plant_id_test);
        },

        async clickPost(){
            await this.axios.post("/post-address",{post:this.plantinfo.post}).then((res) => {
                if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                    const zipinfo =  res.data.resultData.zipinfo;
                    this.plantinfo.tdfk_cd = zipinfo.tdfk_cd;
                    this.plantinfo.address1 = zipinfo.address1;
                    this.plantinfo.address2 = zipinfo.address2;

                }else{
                    this.plantinfo.address1 = '';
                }
            });

        },

        /**
         * プラント情報の取得
         */
        async getPlantInfo(plant_id){

            this.setOverlay(true);
            //Get Plant All Data
            await this.axios.post("/plant-get",{plant_id:plant_id}).then((res) => {
                this.setOverlay(false);
                if (res.data.resultCode == 0) {
                    const p = res.data.resultData.plantinfo;
                    //set vue data
                    for(const prop in this.plantinfo){
                        if(p[prop]!==undefined && this.plantinfo[prop]!==undefined){
                            this.plantinfo[prop] = p[prop];
                        }
                    }
                    this.plantinfo.chk_ukeire_do = res.data.resultData.ukeire_do;
                    this.plantinfo.chk_kairyo_zai= res.data.resultData.kairyozai;
                    this.plantinfo.jituseki = res.data.resultData.jituseki;
                }
            });
        },

        /**
         * 住所から緯度経度変換処理
         */
        addressToLatlng(){

            //エラーチェック
            if(!this.plantinfo.tdfk_cd && !this.plantinfo.address1){
                return ;
            }

            const address = {
                tdfk_cd:this.plantinfo.tdfk_cd,
                address1:this.plantinfo.address1,
                address2:this.plantinfo.address2,
            };
            //住所変換
            this.axios
                .post("/plant-address-latlng",address )
                .then((res) => {
                    if (res.data.resultCode == 0) {
                        this.plantinfo.lat = res.data.resultData.geo.lat;
                        this.plantinfo.lng = res.data.resultData.geo.lng;
                    }else{
                        this.plantinfo.lat = ''; 
                        this.plantinfo.lng = ''; 
                    }
                });

        },

        async clickSave() {


            //error clear
            this.clearError();

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }

            //pdf file
            this.pdf_file = this.$refs.file.files[0];
            this.moshikomi_file = this.$refs.mfile.files[0];
            const formData = new FormData();
            formData.append('plantinfo', JSON.stringify(this.plantinfo));
            formData.append('pdffile', this.pdf_file);
            formData.append('moshikomifile', this.moshikomi_file);

            this.axios
                .post("/plant-update", formData,{
                        Headers:{
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data',
                        }
                        })
                .then((res) => {
                    if (res.data.resultCode == 0) {
                        if(this.plantinfo.plant_id==0){
                            this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,'プラント情報'));
                            this.$router.push({name: "PlantList"});
                        }else{
                            this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'プラント情報'));
                            this.$router.push({name: "PlantView"});
                        }
                    }
                });
        },
        async clickBack() {
            this.$router.go(-1);
        },

        /**
         * 郵便番号庵フォーカス
         * ハイフンを付与する
         */
        blurPost() {
            if (this.isPostCode(this.plantinfo.post)) {
                if (this.plantinfo.post.length === 7) {
                    this.plantinfo.post = this.plantinfo.post.slice(0, 3) + "-" + this.plantinfo.post.slice(3);
                }
            }
        },

        /**
         * 入力チェック
         */
        inputCheck()
        {
            //会員ID
            if(this.plantinfo.member_id === ''||this.plantinfo.member_id===0){
                this.err.member_id = [MESSAGE.formatByArr(MESSAGE.E002,'会員')];
            }
            //プラント名
            if(this.plantinfo.plant_name === ''){
                this.err.plant_name = [MESSAGE.formatByArr(MESSAGE.E001,'プラント名')];
            }

            //郵便番号
            if(this.plantinfo.post ===''){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001,'郵便番号')];
            }else{
                if( !this.isPostCode(this.plantinfo.post)){
                    this.err.post = [MESSAGE.formatByArr(MESSAGE.E005,'郵便番号の形式')];
                }
            }

            //都道府県
            if(this.plantinfo.tdfk_cd === ''){
                this.err.tdfk_cd = [MESSAGE.formatByArr(MESSAGE.E002,'都道府県')];
            }

            //住所１
            if(this.plantinfo.address1 === ''){
                this.err.address1 = [MESSAGE.formatByArr(MESSAGE.E001,'住所（市区町村）')];
            }
            
            //緯度経度
            if(this.plantinfo.lat=== '' ){
                this.err.lat = [MESSAGE.formatByArr(MESSAGE.E001,'緯度')];
            }
            if(this.plantinfo.lng=== '' ){
                this.err.lng = [MESSAGE.formatByArr(MESSAGE.E001,'経度')];
            }

            //稼働年月
            if(this.plantinfo.kado_y !=='' || this.plantinfo.kado_m !==''){
                if(this.plantinfo.kado_y ==''){
                    this.err.kado_y = [MESSAGE.formatByArr(MESSAGE.E001,'稼働年')];
                }
                if(this.plantinfo.kado_m ==''){
                    this.err.kado_m = [MESSAGE.formatByArr(MESSAGE.E001,'稼働月')];
                }
            }

            //申込みURL
            if(this.plantinfo.moshikomi_kbn == 1 && this.plantinfo.moshikomi_url ==''){
                this.err.moshikomi_url = [MESSAGE.formatByArr(MESSAGE.E001,'申込みURL')];
            }
            if((this.plantinfo.moshikomi_kbn == 2) && ((!this.$refs.mfile.files[0]) && ((this.plantinfo.moshikomi_pdf == '') || (this.plantinfo.moshikomi_pdf == null)))){
                this.err.moshikomi_pdf = [MESSAGE.formatByArr(MESSAGE.E002,'申込み用紙')];
            }

            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
        },

        /**
         * マップを開く
         */
        clickMap(){
            var param = {
                lat:this.plantinfo.lat,
                lng:this.plantinfo.lng,
            }
            this.$refs.refDlgMap.open(param)
        },
        /**
         * マップからのコールバック
         */
        callBackMap(latlng){
            this.plantinfo.lat = latlng.lat;
            this.plantinfo.lng = latlng.lng;
        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        clickFileUpload(){
            this.$refs.file.click();
        },
        fileChange(){
            this.uploadFileName = this.$refs.file.files[0].name;
        },
        clickMoshikomiUpload(){
            this.$refs.mfile.click();
        },
        moshikomiChange(){
            this.uploadMoshikomiName = this.$refs.mfile.files[0].name;
        },
    },
};

</script>
