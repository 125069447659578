<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-database</v-icon>
            プラント管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    プラント一覧
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >プラント抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row>
                        <table class="table-no-line">
                            <colgroup>
                            <col width="200">
                            <col>
                            <col width="200">
                            </colgroup>
                            <tr>
                                <th>会員CD</th>
                                <td>
                                    <v-text-field
                                        v-model="member_cd"
                                        maxlength="100"
                                        solo
                                        dense
                                        outlined
                                        hide-details=""
                                    />
                                </td>
                                <th>会員名</th>
                                <td>
                                    <v-text-field
                                        v-model="member_name"
                                        maxlength="100"
                                        solo
                                        dense
                                        outlined
                                        hide-details=""
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>プラント名</th>
                                <td colspan="3">
                                    <v-text-field
                                        v-model="plant_name"
                                        maxlength="100"
                                        solo
                                        dense
                                        outlined
                                        hide-details=""
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>支部</th>
                                <td>
                                    <v-select
                                        v-model="sel_shibu"
                                        dense
                                        outlined
                                        hide-details=""
                                        height="32"
                                        :items="shibu_list"
                                        item-text="shibu_name"
                                        item-value="shibu_cd"
                                    >
                                    </v-select>
                                </td>
                                <th>都道府県</th>
                                <td>
                                    <v-select
                                        v-model="sel_tdfk"
                                        dense
                                        outlined
                                        hide-details=""
                                        height="32"
                                        :items="tdfk_list"
                                        item-text="tdfk_name"
                                        item-value="tdfk_cd"
                                    ></v-select>
                                </td>
                            </tr>
                            <tr>
                                <th>最終更新日</th>
                                <td colspan="3">
                                    <div class="d-inline-flex txt-center py-3">
                                        <input-date-fld
                                            :date.sync="from_date"
                                            :error="isError(err.from_date)"
                                        />
                                        <div class="mx-10">～</div>
                                        <input-date-fld
                                            :date.sync="to_date"
                                            :error="isError(err.to_date)"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn
                                    large
                                    color="blue"
                                    @click="clickSearch(0)"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>
        <v-divider class="mb-5"></v-divider>
        <v-row class="mx-1 align-center">
            <v-col class="text-left mb-1"
                >全 <span class="member-total">{{ plant_list.length }}</span> 件　{{ (page - 1)*perPage + 1 }}件目 〜
                {{ page == allPageCnt? plant_list.length : page*perPage }}件目</v-col
            >
            <v-col class="text-right mb-1">
                <v-btn class="white--text" color="teal" @click="clickExcel"
                    >Excel出力</v-btn
                >
            </v-col>
        </v-row>
        <v-simple-table fixed-header height="auto" class="datatable mx-5" dense>
            <template v-slot:default>
                <col style="width: 7%" />
                <col style="width: 15%" />
                <col style="width: 10%" />
                <col style="width: auto" />
                <col style="width: 20%" />
                <col style="width: 10%" />
                <col style="width: 5%" />
                <thead>
                    <tr>
                        <th>会員CD</th>
                        <th>会員名</th>
                        <th>プラントCD</th>
                        <th>プラント名</th>
                        <th>住所</th>
                        <th>最終更新日</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in disp_plant_list" :key="item.member_no">
                        <td class="text-left">{{ item.member_cd }}</td>
                        <td class="text-left">{{ item.member_name }}</td>
                        <td class="text-left">{{ zeroPadding(item.plant_num, 3)}}</td>
                        <td class="text-left">{{ item.plant_name }}</td>
                        <td class="text-left">{{ item.address3 }}</td>
                        <td class="text-left">{{ item.last_upddate }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-2 mr-1"
                                color="btn-edit"
                                @click="clickEdit(item.plant_id)"
                                >詳細</v-btn
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider class="mb-1"></v-divider>
        <div class="text-center">
            <v-pagination
                v-model="page"
                circle
                :length="allPageCnt"
                @input="pageChange"
            ></v-pagination>
        </div>
    </v-container>
</template>
<style scoped>
.table-no-line tr, .table-no-line td , .table-no-line th {
    border: none;
}
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px 16px !important;
}
.v-input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 6px;
}
.member-total {
    color: red;
    font-size: 24px;
}
.width-200 {
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld";
import { CompatSource } from "webpack-sources";
import { CONSTANT } from '../constant';

const search = 0;
export default {
    name: "PlantList",
    components: { InputDateFld },
    data() {
        return {
            //-- ページ関連
            page: 1,
            perPage: 10,
            allPageCnt: 0,

            //-- expand
            isExpand: [],
            expand: true,

            //-- search conditions
            member_cd: '',
            member_name: '',
            plant_name: '',

            //-- listbox datas
            tdfk_list: [],
            sel_tdfk: "",
            shibu_list: [],
            sel_shibu: "",

            //-date from to
            from_date: "",
            to_date: "",
            //-- plant datas
            plant_list: [],
            disp_plant_list: [],
            err: ({} = {
                from_date: [],
                to_date: [],
            }),
        };
    },
    mounted() {
        if (history.state == "entry") {
            if (sessionStorage.getItem('plant_list_page') !== null) {
                this.page = Number(sessionStorage.getItem('plant_list_page'));
                this.disp_plant_list = this.plant_list.slice((this.page-1)*this.perPage, this.page*this.perPage);
            }
        }
        history.replaceState("entry", null);
        if (this.$route.params.hasOwnProperty('type')){
                this.page = Number(sessionStorage.getItem('plant_list_page'));
                this.disp_plant_list = this.plant_list.slice((this.page-1)*this.perPage, this.page*this.perPage);
        }

        this.getInit();
    },
    methods: {
        clickExpand() {
            this.expand = !this.expand;

        },
        /**
         * 初期化
         */
        async getInit() {
            //コンボのデータ取得
            const res = await this.axios.post("/plant-list-init");
            if (res.data.resultCode == 0) {
                this.tdfk_list = [
                    { tdfk_cd: "", tdfk_name: "全て" },
                    ...res.data.resultData.tdfk,
                ];
                this.shibu_list = [
                    { shibu_cd: "", shibu_name: "全て" },
                    ...res.data.resultData.shibu,
                ];
            }
            // プラント一覧取得
            this.getPlantList();
        },
        async getPlantList(){
            const postData = {
                member_cd: this.member_cd,
                member_name: this.member_name,
                plant_name: this.plant_name,
                shibu_id: this.sel_shibu,
                tdfk_cd: this.sel_tdfk,
                last_upddate_from: this.from_date,
                last_upddate_to: this.to_date,
            }
            this.setOverlay(true);
            const res = await this.axios.post("/plant-search", postData);
            this.setOverlay(false);
            if (res.data.resultCode == 0) {
                this.plant_list = res.data.resultData.plants;
                this.allPageCnt = Math.ceil(this.plant_list.length / this.perPage);
                this.disp_plant_list = this.plant_list.slice((this.page-1)*this.perPage, this.page*this.perPage);
            }

        },

        clickSearch(){
            this.page = 1
            this.getPlantList();
        },
        clickEdit(plantId) {
            sessionStorage.setItem('plant_list_page', this.page);
            this.$store.commit('setPlantId', plantId);
            this.$router.push({name: "PlantView"});
        },
        /**
         * エクセルファイルのダウンロード
         */
        clickExcel() {
            const postData = {
                member_cd: this.member_cd,
                member_name: this.member_name,
                plant_name: this.plant_name,
                shibu_id: this.sel_shibu,
                tdfk_cd: this.sel_tdfk,
                last_upddate_from: this.from_date,
                last_upddate_to: this.to_date,
            }
            this.setOverlay(true);
            this.axios.post("/plant-search-to-excel", postData)
            .then((res)=>{
                this.setOverlay(false);

                if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
                    var blob = this.toBlob(res.data.resultData.excelData, 'application/vnd.ms-excel')  ;
                    var filename = res.data.resultData.fileName; 
                    var link = document.createElement('a');

                    document.body.appendChild(link);
                    link.href = URL.createObjectURL(blob);
                    link.download = filename; 
                    link.click();
                    document.body.removeChild(link);
                }
            });

        },
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},
        pageChange(pageNumber) {
            // this.$router.push({ name: 'PlantList', params: { 'page': pageNumber }});
            this.page = pageNumber;
            this.disp_plant_list = this.plant_list.slice((pageNumber-1)*this.perPage, pageNumber*this.perPage);
        },
    },
};
</script>

