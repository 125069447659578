<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                elevation="2"
                color="primary"
                v-bind="attrs"
                v-on="on">
                {{compTitle}}
            </v-btn>
        </template>
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>会員ダイアログ</v-toolbar-title>
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-5">
                <v-text-field
                    class="mt-6 ml-5 mr-5"
                    label="会員名"
                    append-icon="mdi-magnify"
                    v-model="memberName"
                    @keydown.enter="enterMemberName"
                >
                </v-text-field>
                <v-simple-table
                    dense
                    class="pa-1 ml-4 mr-4"
                    height="300"
                    fixed-header
                >
                    <col style="width: 80px" />
                    <col style="width: 80px" />
                    <col style="width: auto" />
                    <thead>
                        <tr>
                            <th>選択</th>
                            <th>CD</th>
                            <th>名前</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in dataList" :key="item.member_cd">
                            <td>
                                <v-btn small color="primary" @click="clickSelect(item)">選択</v-btn>
                            </td>
                            <td>{{ item.member_cd }}</td>
                            <td>{{ item.member_name }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
            <v-card class="ma-5 text-right" elevation="0">
                <v-btn color="primary" @click="dialog = false"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapState} from 'vuex'
import { CONSTANT } from "../../constant";
export default {
    components: { },
    props:{
        btnTitle:{
            type:String,
            default:""
        },
        memberCd:{
            type:String,
            default:""
        },
        memberId:{
            type:Number,
            default:0
        },
        memberName:{
            type:String,
            default:""
        }
    },
    computed:{
        compTitle:{
            get(){
                return this.btnTitle;
            }
        },
        compMemberCd:{
            get(){
                return this.memberCd;
            },
            set(value){
                this.$emit('update:memberCd', value);
            }
        },
        compMemberId:{
            get(){
                return this.memberId;
            },
            set(value){
                this.$emit('update:memberId', value);
            }
        },
        compMemberName:{
            get(){
                return this.memberName;
            },
            set(value){
                this.$emit('update:memberName', value);
            }
        }
    },
    data: () => ({
        dialog: false,
        dataList: [],
        orgDataList:[],
    }),
    mounted(){
        this.getMember();
    },
    methods: {
        getMember(){
            this.searchMember();
        },
        enterMemberName() {
            this.dataList = this.orgDataList.filter(function (val) {
                return val.member_name.match(this.memberName);
            }, this);
        },
        clickSelect(item){
            this.compMemberCd = item.member_cd;
            this.compMemberId = item.member_id;
            this.compMemberName = item.member_name;
            this.dialog = false;
        },
        async searchMember(){
            return await this.axios.post("plant-member-search").then((res)=>{
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.dataList = this.orgDataList = res.data.resultData.member_list;
                }

            });
        }
    },
};
</script>
