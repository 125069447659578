<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-account-multiple</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員申込一覧
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mb-5 mx-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row class="d-flex align-center mt-0 ml-2" dense>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold">ステータス</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox
                                dense
                                hide-details=""
                                class="mr-5 d-inline-flex align-center"
                                v-model="searchStatus"
                                v-for="list in jyotai"
                                :error="isError(errorStatus)"
                                :key="list.val"
                                :label="list.kbn_name"
                                :value="list.val"
                            />
                            <div
                                class="controll-error"
                                v-if="isError(errorStatus)"
                            >
                                <span
                                    v-html="dispError(errorStatus)"
                                ></span>
                            </div>
                        </v-col>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold">会員区分</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <div class="input-control">
                                <v-checkbox
                                    dense
                                    hide-details=""
                                    class="mr-5 d-inline-flex"
                                    v-model="searchKbn"
                                    v-for="list in memberKbn"
                                    :error="isError(errorStatus)"
                                    :key="list.val"
                                    :label="list.kbn_name"
                                    :value="list.val"
                                />
                                <div
                                    class="controll-error"
                                    v-if="isError(errorStatus)"
                                >
                                    <span
                                        v-html="dispError(errorStatus)"
                                    ></span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center mt-2 ml-2 mt-0" dense>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">支部</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                v-model="shibuCd"
								class="width-200"
								height="32"
                                outlined
                                dense
                                hide-details=""
                                :items="jyotai"
                                item-text="kbn_name"
                                item-value="val"
                            />
                        </v-col>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">会員名</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                class="tokui-name"
                                maxlength="40"
                                v-model="searchCorpName"
                                dense
                                hide-details=""
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn large color="blue" @click="clickSearch(0)"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>
        <v-simple-table fixed-header height="auto" class="datatable mb-1 mx-5" dense>
            <template v-slot:default>
                <col style="width: 10%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: auto" />
                <col style="width: 15%" />
                <col style="width: 13%" />
                <thead>
                    <tr>
                        <th>状態</th>
                        <th>会員区分</th>
                        <th>申込日時</th>
                        <th>会員名</th>
                        <th>支部</th>
                        <th>処理</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in memberList" :key="item.member_no">
                        <td class="text-left">{{ item.status }}</td>
                        <td class="text-left">{{ item.member_kbn }}</td>
                        <td class="text-left">{{ item.indate_at }}</td>
                        <td class="text-left">{{ item.corp_name }}</td>
                        <td class="text-left">{{ item.shibu_cd }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="teal lighten-3"
                                @click="clickApproval(item)"
                                >承認処理</v-btn
                            >
                            <v-btn
                                small
                                class="ml-2 mr-1"
                                color="btn-edit"
                                :disabled="item.status==1"
                                @click="clickEdit(item)"
                                >照会</v-btn
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center">
            <v-pagination
                v-model="page"
                circle
                :length="allPageCnt"
                :total-visible="20"
                @input="clickSearch(1)"
            ></v-pagination>
        </div>
    </v-container>
</template>
<style scoped>
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px 16px !important;
}
.v-input{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 6px;
}
.width-200{
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../messages";
const search = 0;
export default {
    name: "Member",
    components: {},
    data() {
        return {
            //-- ページ関連
            page: 1,
            perPage: 10,
            allPageCnt: 0,

            //-- expand
            isExpand: [],
            expand: true,

            memberList: [],
            memberCd: "",
            corpName: "",
            status: [],
            errorStatus: [],
            jyotai: ([] = [
                { val: 0, kbn_name: '未承認'},
                { val: 9, kbn_name: '否認'},
            ]),
            memberKbn: ([] = [
                { val: 0, kbn_name: '正会員'},
                { val: 1, kbn_name: '賛助会員'},
            ]),
            searchMemberCd: "",
            searchCorpName: "",
            searchStatus: [0],
            searchKbn: [0],
        };
    },
    mounted() {
        this.getMemberList();
    },
    methods: {
        /**
         * 検索欄の開閉
         */
        clickExpand(){
            this.expand = !this.expand;
        },
        getMemberList() {

        },
    },
};
</script>

