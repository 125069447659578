<template>
	
</template>
<script>
import { CONSTANT } from '../constant';
export default {
	created(){
            this.$store.commit('setMemberId', '');
            this.$router.push({name: "MemberEdit", params: { mode: 'u', memberId: ''}});
	}
}
</script>