import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //ログイン成功時のトークン保存
    token:'',

    //メニューの表示・非表示を保存 true:表示
    showMenu:true,

    //会員ID
    memberId:'',

    //得意先
    tokuisakiList:[],

    //現場
    genbaList:[],

    //コンテナ
    containerList:[],

    //コンテナタイプ
    containerTypeList:[],

    // プラント照会
    plantId:'',

    // 管理者ID
    adminId:'',

    //オーバーレイフラグ
    overlayFlg:false,

    //mail_id
    mailId:'',


  },
  mutations: {
    //メニューの表示・非表示を切り替える
    chgShowMenu(state){
      state.showMenu = !state.showMenu ;
    },
    setShowMenu(state, payload){
      state.showMenu = payload.flg; 
    },
    setToken(state,token){
      state.token = token;
    },

    //会員ID
    setMemberId(state,memberId){
      state.memberId = memberId;
    },
    //プラント照会ID
    setPlantId(state,plantId)
    {
      state.plantId = plantId;
    },
    setTokuisakiList(state, tokuisakiList)
    {
      state.tokuisakiList = tokuisakiList;
    },
    setGenbaList(state, genbaList)
    {
      state.genbaList = genbaList;
    },
    setContainerList(state, containerList)
    {
      state.containerList = containerList;
    },
    setContainerTypeList(state, containerTypeList)
    {
      state.containerTypeList = containerTypeList;
    },
    setAdminId(state, adminId)
    {
      state.adminId = adminId
    },
    showOverlay(state)
    {
      state.overlayFlg = true;
    },
    hideOverlay(state)
    {
      state.overlayFlg = false;
    },
    setMailId(state, mailId)
    {
      state.mailId = mailId
    },

  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'vuex-session',
      storage: window.sessionStorage
    })
  ]
})
