<template>
    <v-app-bar color="blue-grey darken-2" dark app clipped-left v-if="isLogin">
      <v-app-bar-nav-icon @click="chgShowMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>建設発生土プラント管理システム</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined @click="logout">ログアウト</v-btn>
    </v-app-bar>
</template>
<script>
import { MESSAGE } from "../messages";
export default {
  name: 'HeaderBar',
  data: () => ({
            drawer: null,
  }),
    computed:{
        isLogin(){
            return this.$store.state.token==''?false:true;
        }
  },
  methods:{
      chgShowMenu(){
          this.$store.commit('chgShowMenu')
      },
      async logout(){

        const res = await this.swalConfirm(
            MESSAGE.TITLE_CONFIRM,
            MESSAGE.formatByArr(MESSAGE.R004, 'ログアウト'));

        if( !res ){
            return ;
        }

        axios.post('/api/logout');
        this.$store.commit('setToken' ,'');
        this.$router.push('/login')

      }

  }
};
</script>
