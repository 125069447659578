<template>
	
</template>
<script>
import { CONSTANT } from '../constant';
export default {
	created(){
		this.$store.commit('setPlantId', 0);
		this.$router.push({ name: 'PlantEdit'});
	}
}
</script>