<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-map</v-icon>
            プラント管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    プラントマップ
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
		<v-card class="ma-5">
            <v-row class="mt-2 ml-2" dense>
                <v-col cols="9" class="mt-0">
                    <div class="map-area" id="map"></div>
                    <div class="plant-list-area">
                        <div class="table-thead">
                            <table class="datatable mt-3">
                                <col style="width: 25%" />
                                <col style="width: 20%" />
                                <col style="width: 30%" />
                                <col style="width: 15%" />
                                <col style="width: auto" />
                                <thead>
                                    <tr>
                                        <th>会員名</th>
                                        <th>プラント名</th>
                                        <th>住所</th>
                                        <th>受入可能量</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="table-tbody">
                            <table class="datatable">
                                <col style="width: 25%" />
                                <col style="width: 20%" />
                                <col style="width: 30%" />
                                <col style="width: 15%" />
                                <col style="width: auto" />
                                <tbody>
                                        <template v-for="(item, index) in plantDispList"> 
                                        <tr :class="[item.plant_id==selPlant?'select-row':'']">
                                            <td :id="'row-'+item.plant_id">{{item.member_name}}</td>
                                            <td>{{item.plant_name}}</td>
                                            <td>{{item.address3}}</td>
                                            <td>
                                                <span v-if="item.now_gendo_uke!=0">{{item.now_gendo_uke}}</span>
                                                <span v-else class="red--text">不可</span>
                                            </td>
                                            <td class="text-center">
                                                <v-btn
                                                    small
                                                    class="ml-1 mr-1 my-1 btn-edit"
                                                    @click="clickPlantDetail(item)"
                                                    >詳細</v-btn
                                                >
                                            </td>
                                        </tr>
                                        </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </v-col>
                <v-col cols="3" class="d-flex mt-0">
                    <v-card class="search-area pa-5">
                        <v-card-subtitle class="text-h6 font-weight-bold text-center">絞り込み条件</v-card-subtitle>
                        <div class="mt-3">
                            <span>住所</span>
                            <v-divider class="mb-3"></v-divider>
                            <v-text-field
                                v-model="address"
                                outlined
                                dense
                                hide-details=""
                                persistent-hint
                                style="width:300px;"
                                @keydown.enter="changeAddress"
                            ></v-text-field>
                        </div>
                        <div class="mt-3">
                            <span>支部</span>
                            <v-divider class="mb-3"></v-divider>
                            <v-select
                                v-model="selShibu"
                                :items="shibu"
                                item-text="shibu_name"
                                item-value="shibu_cd"
                                outlined
                                dense
                                hide-details=""
                                persistent-hint
                                style="width:200px;"
                                @change="selectShibu"
                                return-object
                            ></v-select>
                        </div>
                        <div class="mt-3">
                            <span>都道府県</span>
                            <v-divider class="mb-3"></v-divider>
                            <v-select
                                v-model="selTdfk"
                                :items="dispTdfk"
                                item-text="tdfk_name"
                                item-value="tdfk_cd"
                                outlined
                                dense
                                hide-details=""
                                persistent-hint
                                style="width:200px;"
                                @change="selectTdfk"
                                return-object
                            ></v-select>
                        </div>
                        <div class="mt-3">
                            <span>受入土質</span>
                            <v-divider class="mb-2"></v-divider>
                            <v-checkbox v-model="doshitsuList" v-for="(item, index) in doshitsu" :key="index"
                                :label="item.kubun_value1"
                                :value="item.kubun_cd"
                                dense
                                outlined
                                hide-details=""
                                persistent-hint
                            />
                        </div>
                        <div class="mt-3">
                            <span>改良土の種類</span>
                            <v-divider class="mb-2"></v-divider>
                            <v-checkbox v-model="kairyoList" v-for="(item, index) in kairyo" :key="index"
                                :label="item.kubun_value1"
                                :value="item.kubun_cd"
                                dense
                                outlined
                                hide-details=""
                                persistent-hint
                            />
                        </div>
                        <div class="mt-3">
                            <span>受入可能量</span>
                            <v-divider class="mb-2"></v-divider>
                            <div class="d-flex align-end">
                                <v-text-field
                                    class="my-2 quantity-box d-inlne-block"
                                    type="text"
                                    maxlength="10"
                                    oninput="value = value.replace(/[^0-9]+/i,'')"
                                    dense
                                    outlined
                                    hide-details=""
                                    persistent-hint
                                    v-model="ukeire_ryo_from"
                                ></v-text-field><span class="mb-2">m3 ~ </span>
                                <v-text-field
                                    class="my-2 quantity-box d-inlne-block"
                                    type="text"
                                    maxlength="10"
                                    oninput="value = value.replace(/[^0-9]+/i,'')"
                                    dense
                                    outlined
                                    hide-details=""
                                    persistent-hint
                                    v-model="ukeire_ryo_to"
                                ></v-text-field><span class="mb-2">m3</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <span>ストック量</span>
                            <v-divider class="mb-2"></v-divider>
                            <div class="d-flex align-end">
                                <v-text-field
                                    class="my-2 quantity-box d-inlne-block"
                                    type="text"
                                    maxlength="10"
                                    oninput="value = value.replace(/[^0-9]+/i,'')"
                                    dense
                                    outlined
                                    hide-details=""
                                    persistent-hint
                                    v-model="stock_ryo_from"
                                ></v-text-field><span class="mb-2">m3 ~ </span>
                                <v-text-field
                                    class="my-2 quantity-box d-inlne-block"
                                    type="text"
                                    maxlength="10"
                                    oninput="value = value.replace(/[^0-9]+/i,'')"
                                    dense
                                    outlined
                                    hide-details=""
                                    persistent-hint
                                    v-model="stock_ryo_to"
                                ></v-text-field><span class="mb-2">m3</span>
                            </div>
                        </div>
                        <div class="search-btn mt-10">
                            <v-btn @click="searchPlant" color="blue" class="white--text">絞り込み</v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <dlg-plant-detail ref="refDlgPlant"></dlg-plant-detail>
    </v-container>
</template>
<style lang="scss" scoped>
.map-area {
    width: 100%;
    height: 500px;
    z-index: 0;
}
.search-area {
    width: 100%;
}
.plant-list-area {
    height: 220px;
    width: 100%;
}
.quantity-box {
    width: 120px;
}
.search-btn {
    display: flex;
    justify-content: center;
}
.table-no-line tr, .table-no-line td , .table-no-line th {
    border: none;
}
.input-control {
    width: 100%;
}
.table-thead{
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
}
.table-tbody{
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 195px;
    width: 100%;
}
.table-thead::-webkit-scrollbar {
    background: transparent;
}
.datatable{
    table-layout:fixed;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
}
.datatable
table,
tr,
th,
td {
    word-wrap:break-word;
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable th, .datatable td {
    padding: 3px 16px !important;
}
.select-row {
		background-color: #eafdff;
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
import DlgPlantDetail from "../components/dialog/DlgPlantDetail.vue";
import "leaflet/dist/leaflet.css";
import L, { Draggable } from "leaflet";
require("leaflet.markercluster");
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import {CONSTANT} from "../constant"
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

function markerClick(id) {
	const e = new Event(`marker-${id}`);
	document.dispatchEvent(e);
}
export default {
    name: "PlantMap",
    components: { DlgPlantDetail },
    data() {
        return {
            expand: true,
            url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
            map: {},
            tileLayer: {},
            groupLayer: {},
            markerList: {},
            mapList: {},
            mapIconGreen: {},
            mapIconRed: {},
            mapIconBlue: {},
            selPlant: '',
            plantDispList: [],
            plantList: [],

            shibu: [],
            tdfk:[],
            dispTdfk:[],
            doshitsu: [],
            kairyo: [],

            // 絞り込み条件
            selShibu: '',
            selTdfk: '',
            address: '',
            doshitsuList: [],
            kairyoList: [],
            ukeire_ryo_from: '',
            ukeire_ryo_to: '',
            stock_ryo_from: '',
            stock_ryo_to: '',

            // プラント詳細
            detailDialog: false,
            dispDetail: true,
            plantDetail: {
            },

            lat: 38.263033,
            lng: 140.871437,
            zoom: 13,
        };
    },
    watch: {
        '$route': function (n, o) {
            console.log(n.hash.replace(/^#/, ''))
            console.log('watch')
            if (n.hash.match(/^#/)) {
                document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
            }
            console.log('new, old', [n.hash, o.hash])
        },
    },
    created() {
        window.popClick = this.popClick;
    },
    async mounted() {

        // 赤
        this.mapIconRed = L.icon({
            iconUrl: "/img/marker-red-icon.png",
            iconRetinaUrl: "/img/marker-red-icon-2x.png",
            shadowUrl: "/img/marker-shadow.png",
            iconSize:[25,41],
            shadowSize: [41, 41],
            iconAnchor: [13, 40],
            shadowAnchor: [12, 40],
            popupAnchor: [0, -32]

        });
        // 青
        this.mapIconBlue = L.icon({
            iconUrl: "/img/marker-blue-icon.png",
            iconRetinaUrl: "/img/marker-blue-icon-2x.png",
            shadowUrl: "/img/marker-shadow.png",
            iconSize:[25,41],
            shadowSize: [41, 41],
            iconAnchor: [13, 40],
            shadowAnchor: [12, 40],
            popupAnchor: [0, -32]
        });

        await this.getPlantList();

        // マスタデータ一覧取得
        await this.getMstData();

        // マップの表示
        this.$nextTick(() => {
            this.tileLayer = new L.tileLayer(this.url, { opacity: 0.7 });
            this.map = new L.map("map");
            this.map.addLayer(this.tileLayer);
            this.map.setView([this.lat, this.lng], this.zoom);
            this.addMarker();
            this.map.on("moveend", this.mapMove);
            this.map.on("zoomend", this.mapMove);
        }, this);

        this.getPosition();
    },
    methods: {
        /**
         * マップの移動(ズーム)イベント
         */
        mapMove() {
            const bns = this.map.getBounds();
            const southWest = bns.getSouthWest();
            const northEast = bns.getNorthEast();
            this.plantDispList = this.plantList.filter(function (val) {
                return (
                    southWest.lat < val.lat &&
                    val.lat < northEast.lat &&
                    southWest.lng < val.lng &&
                    val.lng < northEast.lng
                );
            }, this);
        },
        getPosition: async function(){
            await navigator.geolocation.getCurrentPosition(this.get_coord, this.err);
        },
        get_coord: function (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;

            this.map.setView([this.lat, this.lng], this.zoom)
        },
        err: async function () {
            console.log('err');
            // 確認メッセージ
            let res = await this.swalAlert(
                MESSAGE.TITLE_CONFIRM,
                "ブラウザの位置情報の取得が有効になっていません。\n位置情報の取得を有効にしてください。",
                "warning");
            if(!res){
                return ;
            }
        },
        /**
         *  マーカーの追加
         */
        addMarker() {
            //this.groupLayer=new L.featureGroup();
            this.groupLayer = new L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    var markers = cluster.getAllChildMarkers();
                    var flg = false;
                    for (var i = 0; i < markers.length; i++) {
                        if (markers[i].options.ukeire != "0") {
                            flg = true;
                        }
                    }
                    var cls = flg ? "blue" : "red";
                    return new L.DivIcon({
                        html: "<div><span>" + markers.length + "</span></div>",
                        className: "marker-cluster marker-cluster-" + cls,
                        iconSize: new L.Point(40, 40),
                    });
                },
            });
            for (var key in this.plantList) {
                if(!this.plantList[key].lat || !this.plantList[key].lng){
                    // 緯度経度が存在しない場合は次へ
                    continue;
                }
                const mColor = this.plantList[key].now_gendo_uke == 0? 'red':'';
                const mk = L.marker(
                        [this.plantList[key].lat, this.plantList[key].lng],
                        {
                            icon:
                                this.plantList[key].now_gendo_uke == 0
                                    ? this.mapIconRed
                                    : this.mapIconBlue,
                            // icon: L.spriteIcon(mColor),
                            plantId: this.plantList[key].plant_id,
                            ukeire: this.plantList[key].now_gendo_uke,
                        }
                    );

                //マップ内に表示するプラント名
                const id = this.plantList[key].plant_id;
                const plantName = this.plantList[key].plant_name;
                const plantInf = this.plantList[key];

                mk.bindPopup(
                    `<a onclick="popClick(${id})">${plantName}</a>`
                );
                document.addEventListener(`marker-${id}`, () => {
                    this.clickPlantDetail(plantInf);
                })
                mk.on('click',this.clickMarker);
                this.markerList[this.plantList[key].plant_id] = mk;
                this.groupLayer.addLayer(
                    this.markerList[this.plantList[key].plant_id]
                );
            }

            this.groupLayer.addTo(this.map);

        },
        clickMarker(e) {
            console.log('click marker')
            this.selPlant = e.target.options.plantId;
            window.location.href = '#row-'+ e.target.options.plantId;
        },
        popClick(id){
            const e = new Event(`marker-${id}`);
            document.dispatchEvent(e);
        },
        async getPlantList() {
            const postData = {
                shibu_id: this.selShibu.shibu_cd,
                tdfk_cd: this.selTdfk.tdfk_cd,
                ukeire_do: this.doshitsuList,
                kairyo_zai: this.kairyoList,
                ukeire_ryo_from: Number(this.ukeire_ryo_from),
                ukeire_ryo_to: Number(this.ukeire_ryo_to),
                stock_ryo_from: Number(this.stock_ryo_from),
                stock_ryo_to: Number(this.stock_ryo_to),
            }
            await this.axios.post('/plant-search', postData)
            .then((res) => {
                this.plantList = res.data.resultData.plants;
            })
        },
        async searchPlant() {
            // marker delete
            this.map.removeLayer(this.groupLayer);

            await this.getPlantList();

            // marker add
            this.addMarker();
            this.mapMove();
        },
        getMstData() {
            this.axios.post('/plant-list-init')
            .then((res) => {
                this.shibu = res.data.resultData.shibu;
                this.shibu.unshift({ shibu_name: '全て', shibu_cd: '' });

                this.tdfk = res.data.resultData.tdfk;
                this.tdfk.unshift({ tdfk_name: '全て', tdfk_cd: '' });
                this.dispTdfk = this.tdfk;

                this.doshitsu = res.data.resultData.ukeire_do;
                for(let row in this.doshitsu){
                    this.doshitsuList.push(this.doshitsu[row].kubun_cd);
                }
                this.kairyo = res.data.resultData.kairyo_zai;
                for(let row in this.kairyo){
                    this.kairyoList.push(this.kairyo[row].kubun_cd);
                }
            });
        },
        clickPlantDetail(plantInf){
            this.$refs.refDlgPlant.open(plantInf.plant_id);
            this.plantDetail = plantInf;
        },
        selectShibu () {
            if(this.selShibu.shibu_cd != ''){
                this.lat = this.selShibu.lat;
                this.lng = this.selShibu.lng;
                this.zoom = this.selShibu.scale;

                this.map.setView([this.lat, this.lng], this.zoom);
                this.mapMove();
                this.dispTdfk = this.tdfk.filter( item => item.shibu_cd == this.selShibu.shibu_cd);
            }else{
                this.dispTdfk = this.tdfk;
            }
        },
        selectTdfk () {
            if(this.selTdfk.tdfk_cd != ''){
                this.lat = this.selTdfk.lat;
                this.lng = this.selTdfk.lng;
                this.zoom = this.selTdfk.scale;

                this.map.setView([this.lat, this.lng], this.zoom);
                this.mapMove();
            }
        },
        changeAddress() {
           this.axios.post('address-latlng', { address: this.address }).then((res)=>{
                if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
                    this.lat = res.data.resultData.latlng.lat;
                    this.lng = res.data.resultData.latlng.lng;
                    this.zoom = 12;
                    this.map.setView([this.lat, this.lng], this.zoom);
                    this.mapMove();
                }
           })
        },
    },
};


</script>
