<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-information</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    お知らせ
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-row class="mx-5 align-center">
            <v-col class="text-right mb-3">
                <v-btn color="deep-orange lighten-3" @click="clickNew()">お知らせ新規作成</v-btn>
            </v-col>
        </v-row>
        <v-simple-table fixed-header height="auto" class="datatable mb-1 mx-5" dense>
            <template v-slot:default>
                <col style="width: 80px" />
                <col style="width: 120px" />
                <col style="width: 120px" />
                <col style="width: 150px" />
                <col style="width: auto" />
                <col style="width: 180px" />
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>表示開始</th>
                        <th>表示終了</th>
                        <th>表示区分</th>
                        <th>タイトル</th>
                        <th>編集</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in infolist" :key="item.id">
                        <td class="text-left">{{ item.id }}</td>
                        <td class="text-left">{{ dateFormat(item.display_start) }}</td>
                        <td class="text-left">{{ dateFormat(item.display_end) }}</td>
                        <td class="text-left" :class="[{'red--text':(item.display_flg==0)},{'blue--text':(item.display_flg==1)}]">{{ getJyotaiName(item.display_flg) }}</td>
                        <td class="text-left">{{ item.title }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="btn-edit ma-1"
                                @click="clickEdit(item.id)"
                                >編集
                            </v-btn>
                            <v-btn
                                small
                                class="btn-delete ma-1"
                                @click="clickDelete(item.id)"
                                >削除
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>
<style scoped>

</style>
<script>
import { MESSAGE } from "../messages";
export default {
    name: "Information",
    components: {},
    data() {
        return {
            jyotai: ([] = [
                { val: 0, kbn_name: "表示しない" },
                { val: 1, kbn_name: "表示する" },
            ]),

            infolist: [],
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            this.axios.get("/information").then((res) => {
                this.infolist = res.data.resultData;
            });
        },
        clickNew() {
            this.$router.push({name: "InformationEdit", params: { id: "" }});
        },
        clickEdit(id) {
            this.$router.push({name: "InformationEdit", params: { id: id }});
        },
        async clickDelete(id) {
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, `ID: ${id}`));

            // 「いいえ」の場合、処理しない
            if (!res) {
                return;
            }
            this.axios.delete(`/information/${id}`).then((res) => {
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I003, `ID: ${id}`));
                this.getInfo();
            });
        },
        /**
         * 表示・非表示文言変換
         */
        getJyotaiName(kbn) {
            const nm = this.jyotai.find((row) => row.val === kbn);
            if (!nm) {
                return "";
            }
            return nm.kbn_name;
        },
    },
};
</script>

