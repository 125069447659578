<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-information</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    お知らせ登録
                </v-card-subtitle>
                <v-spacer />
                <v-btn
                    large
                    :loading="saveLoading"
                    :disabled="saveLoading"
                    class="btn-edit ma-2"
                    @click="clickSave"
                    >保存</v-btn
                >
                <v-btn large class="btn-close ma-2" @click="clickBack"
                    >もどる</v-btn
                >
            </v-system-bar>
        </v-card>
        <v-card class="mx-5">
            <div class="pa-5">
                <table class="information-edit-table">
                    <colgroup>
                        <col style="width: 20%;" />
                        <col style="width: 50%;" />
                    </colgroup>
                    <tr>
                        <th>ID</th>
                        <td v-if="mode == 'i'">
                            IDは登録時に自動発番されます
                        </td>
                        <td v-else>
                            {{ infoId }}
                        </td>
                    </tr>
                    <tr>
                        <th>表示日付</th>
                        <td class="pt-2">
                            <div class="d-flex align-center">
                                <input-date-fld :date.sync="inp.displayStart" :error="isError(err.displayStart)" />
                                <span class="mx-4">～</span>
                                <input-date-fld :date.sync="inp.displayEnd" :error="isError(err.displayEnd)" />
                            </div>
                            <div
                                class="controll-error"
                                v-show="isError(err.displayStart)"
                            >
                                <span v-html="dispError(err.displayStart)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>表示・非表示</th>
                        <td>
                            <v-radio-group class="py-2" hide-details="false" v-model="inp.displayFlg" row>
                                <v-radio
                                    v-for="list in jyotai"
                                    :key="list.val"
                                    :label="list.kbn_name"
                                    :value="list.val"
                                    dense
                                    
                                />
                            </v-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <th>タイトル</th>
                        <td>
                            <v-text-field
                                maxlength="80"
                                v-model="inp.title"
                                :error="isError(err.title)"
                                solo
                                dense
                                outlined
                                hide-details=''
                            />
                            <div
                                class="controll-error"
                                v-show="isError(err.title)"
                            >
                                <span v-html="dispError(err.title)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>詳細内容</th>
                        <td>
                            <v-textarea
                                maxlength="480"
                                v-model="inp.content"
                                :error="isError(err.content)"
                                solo
                                dense
                                outlined
                                hide-details=""
                            />
                            <div
                                class="controll-error"
                                v-show="isError(err.content)"
                            >
                                <span v-html="dispError(err.content)"></span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
.information-edit-table {
    table-layout: fixed;
    width: 750px;
    border: 1px solid #ccc;

    th {
        border: 1px solid #ccc;
        text-align: left;
        padding-left: 5px;
        background-color: #555;
        color: white;
        font-weight: normal;
        padding: 5px 5px 5px 5px;
    }
	td {
		border: 1px solid #ccc;
        padding: 5px 5px 5px 5px;
	}
    td .dspfld {
        height: 34px;
        margin: 5px 0;
        border: 1px solid #aaa;
        background-color: #eee;
        font-size: 16px;
    }
	.v-input{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
        padding-top: 6px;
	}
    .width-200{
        width: 200px;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld";
export default {
    name: "InformationEdit",
    components: { InputDateFld },
    data() {
        return {
            mode: 'i',
            infoId: '',
            saveLoading: false,
            inp: {
                id: null,
                displayStart: null,
                displayEnd: null,
                displayFlg: 0,
                title: '',
                content: '',
            },
            err: ({} = {
                displayStart: [],
                displayEnd: [],
                title: [],
                content: [],
            }),
            jyotai: ([] = [
                { val: 0, kbn_name: "表示しない" },
                { val: 1, kbn_name: "表示する" },
            ]),
        };
    },
    mounted() {
        this.clearError();
        this.infoId = this.$route.params.id;

        if(!this.infoId){
            this.mode = 'i';
        }else{
            this.mode = 'u';
            this.getionfo();
        }
    },
    methods: {
        getionfo() {
            this.axios.get(`/information/${this.infoId}`).then((res) => {
                this.inp.id = res.data.resultData.id;
                this.inp.displayStart = res.data.resultData.display_start;
                this.inp.displayEnd = res.data.resultData.display_end;
                this.inp.displayFlg = res.data.resultData.display_flg;
                this.inp.title = res.data.resultData.title;
                this.inp.content = res.data.resultData.content;
            });
        },
        async clickSave() {

            this.clearError();

            // エラーチェック
            if (await this.inputCheck()) {
                this.saveLoading = null;

                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                this.mode == "i" ? MESSAGE.R001 : MESSAGE.R002
            );

            if (!res) {
                this.saveLoading = null;
                return;
            }

            if (this.mode === "i") {
                this.axios.post("/information", this.inp).then((res) => {
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,'お知らせ'));
                    this.$router.push({name: "Information"});
                });
            }else{
                this.axios.put(`/information/${this.infoId}`, this.inp).then((res) => {
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'お知らせ'));
                    this.$router.push({name: "Information"});
                });
            }
        },
        inputCheck() {
            //-- 表示日付
            if (this.inp.displayEnd && (this.dateFormat(this.inp.displayStart) > this.dateFormat(this.inp.displayEnd))) {
                this.err.displayStart = ["終了日に開始日より以前の日付が入力されています"];
                this.err.displayEnd = ["終了日に開始日より以前の日付が入力されています"];
            }

            //-- タイトル 
            if (!this.inp.title) {
                this.err.title = [MESSAGE.formatByArr(MESSAGE.E001, "タイトル")];
            }

            //-- 詳細内容
            if (!this.inp.content) {
                this.err.content = [MESSAGE.formatByArr(MESSAGE.E001, "詳細内容")];
            }

            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        clickBack() {
            this.$router.go(-1);
        },
    },
};
</script>

