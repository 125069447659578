<template>
  <v-card class="mx-auto">
    <v-navigation-drawer
      app
      v-model="isMenu"
      clipped
      color="blue lighten-5"
      v-if="isLogin"
    >
      <v-container class="pl-0 pr-0">
        <v-list dense nav>

          <v-list-item-group v-model="selectedMenu" color="orange">
            <v-list-item
              to="/"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ダッシュボード</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
            <v-toolbar-title class="text-subtitle-1"
              >会員管理</v-toolbar-title
            >
          </v-toolbar>


            <v-list-item
              v-for="nav_list in nav_member_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >プラント管理</v-toolbar-title
              >
            </v-toolbar>
            <v-list-item
              v-for="nav_list in nav_plant_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>







            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >マスタ管理・メンテナンス</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_master_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >その他開発時メニュー</v-toolbar-title
              >
            </v-toolbar>

            <v-list-item
              v-for="nav_list in nav_sonota_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </v-card>
</template>
<style scope>
.menu-title {
    background-color: red im !important;
}
</style>
<script>
export default {
    data: () => ({
        drawer: null,
        selectedMenu: 1,
        nav_member_lists: [
            {
                name: "会員一覧",
                icon: "mdi-account-multiple",
                link: "/member",
                no: 1,
            },
            {
                name: "会員登録",
                icon: "mdi-account",
                link: "/member-new",
                no: 2,
            },
            {
                name: "お知らせ",
                icon: "mdi-information",
                link: "/information",
                no: 30,
            },
        ],
        nav_plant_lists:[
            {
                name: "プラント一覧",
                icon: "mdi-database",
                link: "/plant",
                no: 4,
            },
            {
                name: "プラント登録",
                icon: "mdi-database-plus",
                link: "/plant-new",
                no: 5,
            },
            {
                name: "プラントマップ",
                icon: "mdi-map",
                link: "/plantmap",
                no: 6,
            },
        ],
        nav_sonota_lists:[
            {
                name: "一般ページマップリンク",
                icon: "mdi-map",
                link: "/mobile",
                no: 7,
            },
        ],
        nav_master_lists:[
            {
                name: "都道府県・支部マスタ",
                icon: "mdi-database",
                link: "/tdfk-shibu-mst",
                no: 8,
            },
            {
                name: "メンテナンス設定",
                icon: "mdi-database",
                link: "/maintenance",
                no: 21,
            },
            {
                name: "管理者設定",
                icon: "mdi-database",
                link: "/adminlist",
                no: 22,
            },
            {
                name: "メールテンプレート",
                icon: "mdi-database",
                link: "/maillist",
                no: 23,
            },
            {
                name: "プラント更新期限設定",
                icon: "mdi-database",
                link: "/updatelimit",
                no: 24,
            },
            {
                name: "DL資料管理",
                icon: "mdi-database",
                link: "/siryo",
                no: 25,
            },
        ],
        nav_mente_lists:[
        ],

    }),
    computed: {
        isLogin() {
            return this.$store.state.token == "" ? false : true;
        },
        isMenu: {
            get(){
                return this.$store.state.showMenu;
            },
            set(value){
                this.$store.commit('setShowMenu',{flg:value})
            }
        }
    },
    methods: {
        click_item() {},
    },
};
</script>
