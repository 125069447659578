<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
		<v-sheet
			elevation="2"
			rounded="0"
			height="40"
			max-width="100%"
			class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
		>
			<v-icon class="white--text mb-2">mdi-database</v-icon>
			マスタ管理・メンテナンス 
		</v-sheet>
		<v-card class="ma-5 rounded" >
			<v-system-bar color="gray" height="50">
				<v-card-subtitle class="text-h6 font-weight-bold">
					プラント更新期限設定
				</v-card-subtitle>
				<v-spacer />
			</v-system-bar>
		</v-card>
	</v-card >

	<v-card class="ma-5 pa-5" outlined >
		<div class="blue-grey--text mb-2">
			プラントの更新期限が下記の日数を超えた場合、会員のダッシュボードに表示されます。
		</div>
		<div class="d-inline-flex txt-center">
			<div>更新期限：</div>
			<v-text-field outlined 
			type="number"
			dense
			hide-details="auto"
			:error="isError(err)"
			style="width:80px"
			v-model="updateLimit"
			></v-text-field>
			<div>日以内 </div>
			<div class="ml-10">
				<v-btn small class="pa-0 mx-2 btn-edit" @click="clickSave">保存</v-btn>
			</div>
		</div>
		<div class="controll-error ml-2">
			<span v-html="dispError(err)" ></span>
		</div>
	</v-card>

	</v-container>
</template>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
export default {
	data() {
		return {
			err:[],
			updateLimit:''
		}
	},
	mounted(){
		this.getUpdateLimit();
	},
	methods:{
		async getUpdateLimit(){
			this.adminList = [];
			const res = await this.axios.post('get-update-limit');
			if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
				this.updateLimit = res.data.resultData.update_limit_day;
			}
		},
		async clickSave(){
			this.err = [];
			if(!this.updateLimit){
				this.err = [MESSAGE.formatByArr(MESSAGE.E001,'更新期限')];
				return;
			}
			const res = await this.axios.post('set-update-limit',
			{'update_limit_day':this.updateLimit});
			if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'更新期限'));
			}

		}
	}


}
</script>