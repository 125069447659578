<template>
    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="700px">
			<v-card>
			    <v-toolbar height="40" dark>
				<v-toolbar-title>プラント詳細</v-toolbar-title>
				<v-spacer />
			    </v-toolbar>
			    <v-container>
				<div class="basic-area px-10 mt-5">
					<div class="font-weight-black" id="scrollTop">基本情報</div>
					<v-divider class="mb-3" ></v-divider>
					<table class="plant-list-table">
						<col style="width: 180px" />
						<col style="width: auto" />
						<tr>
							<th class="text-left">プラント名称</th>
							<td class="pl-1" align="left">{{ plantinfo.plant_name }}</td>
						</tr>
						<tr>
							<th class="text-left">所在地</th>
							<td class="pl-1" align="left">
								<p>〒{{plantinfo.post}}</p>
								<p>{{plantinfo.tdfk_name}}{{plantinfo.address1}}{{plantinfo.address2}}</p>
								</td>
						</tr>
						<tr>
							<th class="text-left">TEL</th>
							<td class="pl-1">{{ plantinfo.tel }}</td>
						</tr>
						<tr>
							<th class="text-left">担当</th>
							<td class="pl-1" align="left">{{ plantinfo.tantou_name }}</td>
						</tr>
						<tr>
							<th class="text-left">プラント認証番号</th>
							<td class="pl-1" align="left">{{ plantinfo.actec_no }}&nbsp;{{ plantinfo.actec_no_pre }}</td>
						</tr>
						<tr>
							<th class="text-left">営業日</th>
							<td class="pl-1" align="left">{{ plantinfo.sales_day }}</td>
						</tr>
						<tr>
							<th class="text-left">営業時間</th>
							<td class="pl-1" align="left">{{plantinfo.sales_time}}</td>
						</tr>
					</table>
				</div>
				<div class="px-10 mt-10">
					<div class="font-weight-black">原料土／改良土</div>
					<v-divider class="mb-3"></v-divider>
                    <div class="text-right">
                        <span>最終更新日：</span>
                        <span style="width:150px; display:inline-block">{{ plantinfo.las_upddate }}</span>
                    </div>
					<table class="plant-list-table line">
					<col style="width: 100px" />
					<col style="width: auto" />
					<col style="width: 100px" />
					<col style="width: 150px" />
					<col style="width: 60px" />
					<tr>
						<th class="py-1"></th>
						<th class="py-1">土質</th>
						<th class="py-1">料金</th>
						<th class="py-1">受入可能量<br/>ストック量</th>
						<th class="py-1">受入<br/>可否</th>
					</tr>
					<tr>
						<th>原料土</th>
						</td>
						<td align="left">{{ plantinfo.hp_gendo_uke_name }}</td>
						<td align="right">{{ numberFormat(plantinfo.hp_gendo_uke_kingaku) }}&nbsp;円</td>
						<td align="right">{{ numberFormat(plantinfo.now_gendo_uke) }}&nbsp;m3</td>
						<td align="center"><span :class="[plantinfo.now_gendo_uke == 0?'red-font':'']">{{ plantinfo.now_gendo_uke == 0 ? '不可' : '可' }}</span></td>
					</tr>
					<tr>
						<th>改良土</th>
						<td align="left">{{ plantinfo.hp_kaido_name }}</td>
						<td align="right">{{numberFormat(plantinfo.hp_kaido_kingaku) }}&nbsp;円</td>
						<td align="right">{{numberFormat(plantinfo.now_kaido_stock) }}&nbsp;m3</td>
						<td></td>
					</tr>
					<tr>
						<th>料金表</th>
						<td colspan="4">
							<ryokin-file 
							ignore_flg="false"
							title="料金表をダウンロードする"
							not_title="料金表はありません"
							:plant_id="plantinfo.plant_id" />
						</td>
					</tr>
					<tr>
						<th>申込み</th>
						<td v-if="plantinfo.moshikomi_kbn == 1" colspan="4">
							<a :href="plantinfo.moshikomi_url" target="_blank" rel="noopener noreferrer">{{ plantinfo.moshikomi_url }}</a>
						</td>
						<td v-else-if="plantinfo.moshikomi_kbn == 2" colspan="4">
							<moshikomi-file 
							ignore_flg="true"
							title="申込み用紙をダウンロードする"
							not_title="申込み用紙はありません"
							:plant_id="plantinfo.plant_id" />
						</td>
						<td v-else colspan="4">
						</td>
					</tr>
					</table>
				</div>
				<div class="btns-area ma-10">
					<v-btn class="mr-2 btn-close" @click="dialog=false">閉じる</v-btn>
					<v-btn class="btn-edit" @click="showPlantView">プラント照会へ</v-btn>
				</div>
			    </v-container>
			</v-card>
        </v-dialog>
</template>
<style scoped>
    .plant-list-table {
 	 	table-layout:fixed;
		width: 100%;
	 	text-align: center;
	 	border-collapse: collapse;
	 	border-spacing: 0;
	}
	.plant-list-table tr th {
	 	padding: 10px;
	 	background: lightgray;
	 	border: solid 1px #778ca3;
	}
	.plant-list-table tr td {
		word-wrap:break-word;
		padding: 5px;
		height: 10px !important;
		border-bottom: solid 1px #778ca3;
		border-top: solid 1px #778ca3;
		border-left: solid 1px #778ca3;
		border-right: solid 1px #778ca3;
	}
    .btns-area {
	    display:flex;
	    justify-content: right;
	}
</style>
<script>
import {mapState} from 'vuex'
import RyokinFile from "../../components/RyokinFile.vue"
import MoshikomiFile from "../../components/MoshikomiFile.vue"
import { CONSTANT } from "../../constant";
export default {
    components: { RyokinFile, MoshikomiFile },
    props:{
    },
    data: () => ({
        dialog: false,
		plantinfo:[],
    }),
    mounted(){
    },
    methods: {
        open(plant_id) {
            this.dialog = true;
			this.getPlantInfo(plant_id);
        },
        showPlantView() {
            this.$store.commit('setPlantId', this.plantinfo.plant_id);
            this.$router.push({name: "PlantView", params: { type: 'Map' }});
        },
		async getPlantInfo(plant_id){
			await this.axios.post("/plant-get",{plant_id:plant_id})
			.then((res)=>{
				if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
					this.plantinfo = res.data.resultData.plantinfo;
				}else{
					this.dialog = false;
				}

			})
		}
    },
};
</script>
