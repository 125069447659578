<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-account-multiple</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員一覧
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mb-5 mx-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >会員抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row class="d-flex align-center mt-0 ml-2" dense>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">会員CD</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                class="tokui-cd"
                                maxlength="40"
                                v-model="search.memberCd"
                                dense
                                hide-details=""
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">支部</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                v-model="search.shibuCd"
								class="width-200"
								height="32"
                                outlined
                                dense
                                hide-details=""
                                :items="shibulist"
                                item-text="shibu_name"
                                item-value="shibu_cd"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center mt-2 ml-2 mt-0" dense>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold">会員区分</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <div class="input-control">
                                <v-checkbox
                                    dense
                                    hide-details=""
                                    class="mr-5 d-inline-flex"
                                    v-model="search.memberKbn"
                                    v-for="list in memberKbn"
                                    :key="list.val"
                                    :label="list.kbn_name"
                                    :value="list.val"
                                />
                            </div>
                        </v-col>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">住所</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                class="tokui-name"
                                maxlength="40"
                                v-model="search.address"
                                dense
                                hide-details=""
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center mt-2 ml-2 mt-0" dense>
                        <v-col cols="1">
                            <v-subheader class="text-subtitle-1 font-weight-bold title">会員名</v-subheader>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                class="tokui-name"
                                maxlength="40"
                                v-model="search.memberName"
                                dense
                                hide-details=""
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn large color="blue" @click="getMemberList(1)"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>
        <v-row class="mx-5 align-center">
            <v-col class="text-left mb-1">全 <span class="member-total">{{ memberCnt }}</span> 件　{{ pageFrom }}件目 〜 {{ pageTo }}件目</v-col>
            <v-col class="text-right mb-1">
                <v-btn class="white--text" color="teal" @click="clickExcel()">Excel出力</v-btn>
            </v-col>
        </v-row>
        <v-simple-table fixed-header height="auto" class="datatable mb-1 mx-5" dense>
            <template v-slot:default>
                <col style="width: 10%" />
                <col style="width: 12%" />
                <col style="width: 20%" />
                <col style="width: 10%" />
                <col style="width: auto" />
                <col style="width: 8%" />
                <thead>
                    <tr>
                        <th>会員CD</th>
                        <th>会員区分</th>
                        <th>会員名</th>
                        <th>支部</th>
                        <th>住所</th>
                        <th>編集</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in memberlist" :key="item.member_cd">
                        <td class="text-left">{{ item.member_cd }}</td>
                        <td class="text-left">{{ getKbnName(item.member_kbn) }}</td>
                        <td class="text-left">{{ item.member_name }}</td>
                        <td class="text-left">{{ item.shibu_name }}</td>
                        <td class="text-left">{{ item.address3 }}</td>
                        <td class="text-center">
                            <div class="d-flex d-inline-flex">
                            <v-btn small class="ml-2 mr-1" 
                            color="btn-delete" 
                            @click="clickDelete(item.member_id, item.member_cd)">
                                削除
                            </v-btn>
                            <v-btn
                                small
                                class="ml-2 mr-1"
                                color="btn-edit"
                                @click="clickInqury(item.member_id)"
                            >詳細</v-btn>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center mx-5">
            <v-pagination
                v-model="search.page"
                circle
                :length="pageCnt"
                :total-visible="20"
                @input="getMemberList(0)"
            ></v-pagination>
        </div>
    </v-container>
</template>
<style scoped>
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px 16px !important;
}
.v-input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 6px;
}
.member-total {
    color: red;
    font-size: 24px;
}
.width-200 {
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import { CONSTANT } from "../constant";
export default {
    name: "Member",
    components: {},
    data() {
        return {
            //-- ページ関連
            
            pageCnt: 0,
            memberCnt: 0,
            pageFrom: 0,
            pageTo: 0,

            //-- expand
            expand: true,

            memberlist: [],
            shibulist: [],
            memberKbn: ([] = [
                { val: 1, kbn_name: '正会員'},
                { val: 2, kbn_name: '賛助会員'},
            ]),
            //データ用
            // data: ({} = {
            //     memberCd: "",
            //     shibuCd: "",
            //     memberKbn: [1],
            //     address: "",
            //     memberName: "",
            //     page: 1,
            //     perPage: 10,
            // }),
            //検索
            search: ({} = {
                memberCd: "",
                shibuCd: "",
                memberKbn: [1],
                address: "",
                memberName: "",
                page: 1,
                perPage: 10,
            }),
        };
    },
    mounted() {
        const saveData = JSON.parse(sessionStorage.getItem('memlist-data'));
        if(saveData){
            this.$data['search'] = saveData;
            // this.$data['data'] = saveData;
        }
        // if (history.state == "entry") {
        //     if (sessionStorage.getItem('memlist-data') !== null) {
        //         this.$data['data'] = JSON.parse(sessionStorage.getItem('memlist-data'));
        //         this.$data['search'] = JSON.parse(sessionStorage.getItem('memlist-data'));
        //     }
        // }
        // if (this.$route.params.hasOwnProperty('type')){
        //         this.$data['data'] = JSON.parse(sessionStorage.getItem('memlist-data'));
        //         this.$data['search'] = JSON.parse(sessionStorage.getItem('memlist-data'));
        // }

        this.getLists();
        this.getMemberList(0);
        history.replaceState("entry", null);
    },
    methods: {
        /**
         * 検索欄の開閉
         */
        clickExpand(){
            this.expand = !this.expand;
        },
        save_storage() {
            sessionStorage.setItem('memlist-data', JSON.stringify(this.$data['search']));
        }, 
        getLists(){
            this.shibulist = [];
            this.axios.get("/member-get-list").then((res) => {
                if(res.data.resultCode == CONSTANT.RETURN_NORMAL ){
                    this.shibulist = res.data.resultData.shibulist;
                    this.shibulist.unshift({shibu_cd: '', shibu_name: 'すべて'});
                }
            });
        },
        getMemberList(mode) {
            if(mode == 1){
                this.search.page = 1;
                // for (let key in this.search) {
                //     this.data[key] = this.search[key];
                // }
            // }else{
            //     this.data.page = this.search.page;
            }

            this.memberlist = [];
            this.axios.post("/member-search", this.search).then((res) => {
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.memberlist = res.data.resultData.datalist;
                    this.pageCnt = res.data.resultData.pageCnt;
                    this.memberCnt = res.data.resultData.memberCnt;
                    this.pageFrom = res.data.resultData.pageFrom;
                    this.pageTo = res.data.resultData.pageTo;
                }
            });
        },
        clickInqury(id){
            this.save_storage();
            this.$store.commit('setMemberId', id);
            this.$router.push({name: "MemberInqury"});
        },
        async clickDelete(member_id, member_cd) {
            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr( MESSAGE.R003,"会員 ("+ member_cd +") " ))
            
            if(!res){
                this.saveLoading = null;
                return ;
            }
            this.setOverlay(true);
            const result = await this.axios.post("/member-delete", { member_id: member_id });
            this.setOverlay(false);
            if(result.data.resultCode == CONSTANT.RETURN_NORMAL){
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I003, member_cd));
                this.getMemberList(1);
            }
        },
        clickExcel(){
            this.setOverlay(true);
            this.axios.post("/member-get-excel", this.search).then((res) => {
                this.setOverlay(false);
                if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
                    var blob = this.toBlob(res.data.resultData.excelData, 'application/vnd.ms-excel')  ;
                    var filename = res.data.resultData.fileName; 
                    var link = document.createElement('a');

                    document.body.appendChild(link);
                    link.href = URL.createObjectURL(blob);
                    link.download = filename; 
                    link.click();
                    document.body.removeChild(link);
                }
            });
        },
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},
        temp(){

        },
    },
};
</script>

