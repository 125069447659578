<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-map</v-icon>
            一般閲覧画面検証
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    一般閲覧画面検証用ページ
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-5 pb-10 d-flex flex-column">
            <div class="mt-5 ml-5" style="height:30px;">
                <span class="text-h6 ">一般閲覧画面起動用QRコード</span>
            </div>
            <img
                width="300px"
                src="@/assets/img/QR_937061.png"
            ></img>
            <div>
                <a class="text-center ml-10" target="_blank" href="https://general.tsuti.net/">https://general.tsuti.net/</a>
            </div>
       </v-card> 
    </v-container>
</template>
<style lang="scss" scoped>
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
export default {
    name: "Member",
    components: {},
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
        
       
    },
};
</script>
