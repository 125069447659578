<template>
    <v-container fluid class="mt-0 pa-0">

	<v-card class="page-menu mb-3" rounded="0"  flat>
            <v-sheet
                elevation="2"
				rounded="0"
                height="40"
				max-width="100%"
                class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
            >
            <v-icon class="white--text mb-2">mdi-account</v-icon>
             会員管理
            </v-sheet>
            <v-card class="ma-5 rounded" >
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                    会員登録
                    </v-card-subtitle>
                    <v-spacer />
                    <v-btn large class="ma-2 btn-edit" @click="clickSave">保存</v-btn>
                    <v-btn large class="ma-2 btn-close" @click="clickBack">戻る</v-btn>
                </v-system-bar>
            </v-card>
	</v-card>



        <v-card class="ma-5">
            <div class="pa-5">
                <table class="member-edit-table">
                    <colgroup>
                        <col width="180" />
                        <col width="150" />
                        <col width="150" />
                        <col width="200" />
                        <col width="150" />
                        <col width="200" />
                    </colgroup>
                    <tr>
                        <th class="text-left pl-3">ステータス<span class="required">(必須)</span></th>
                        <td>
                            <v-select
                                    v-model="inp.status"
                                    class="width-200"
                                    height="32"
                                    outlined
                                    :error="isError(err.status)"
                                    solo
                                    dense
                                    hide-details=""
                                    :items="satuslist"
                                    item-text="name"
                                    item-value="val"
                                />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.status)"
                            >
                                <span v-html="dispError(err.status)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">入会日<span class="required">(必須)</span></th>
                        <td>
                            <input-date-fld :date.sync="inp.nyukaiBi" :error="isError(err.nyukaiBi)" />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.nyukaiBi)"
                            >
                                <span v-html="dispError(err.nyukaiBi)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">退会日</th>
                        <td>
                            <input-date-fld :disabled="taikaiFlg" :date.sync="inp.taikaiBi" :error="isError(err.taikaiBi)" />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.taikaiBi)"
                            >
                                <span v-html="dispError(err.taikaiBi)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">会員区分<span class="required">(必須)</span></th>
                        <td>
                                <v-select
                                    v-model="inp.memberKbn"
                                    class="width-200"
                                    height="32"
                                    outlined
                                    :error="isError(err.memberKbn)"
                                    solo
                                    dense
                                    hide-details=""
                                    :items="kbnlist"
                                    item-text="name"
                                    item-value="val"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.memberKbn)"
                                >
                                    <span v-html="dispError(err.memberKbn)"></span>
                                </div>
                        </td>
                        <th class="text-left pl-3">種別<span class="required">(必須)</span></th>
                        <td colspan="3">
                            <v-select
                                v-model="inp.shubetsuKbn"
                                class="width-200"
                                height="32"
                                :error="isError(err.shubetsuKbn)"
                                outlined
                                solo
                                dense
                                hide-details=""
                                :items="shubetsulist"
                                item-text="name"
                                item-value="val"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.shubetsuKbn)"
                            >
                                <span v-html="dispError(err.shubetsuKbn)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <th class="text-left pl-3">会員CD</th>
                        <template v-if="mode=='u'">
                            <td  colspan="5">
                                <v-sheet
                                    outlined
                                    class="rounded d-inline-block pa-2 dspfld tokui-cd"
                                    elevation="0"
                                >
                                    {{ inp.memberCd }}
                                </v-sheet>
                                <span> (ログインID)</span>
                            </td>
                        </template>
                        <template v-else>
                            <td  colspan="5" >
                                <div class="d-flex d-inline-flex align-end">
                                <v-text-field
                                    maxlength="5"
                                    style="width:100px"
                                    outlined
                                    hide-details="auto"
                                    dense
                                    :error="isError(err.memberCd)"
                                    @change="changeMemberCd"
                                    v-model="inp.memberCd"
                                    elevation="0"
                                >
                                    {{ inp.memberCd }}
                                </v-text-field>
                                <span class="ml-2 text-subtitle-1"> （ログインID）</span>

                                </div>
                                <div class="controll-error ml-2"
                                v-show="isError(err.memberCd)"
                                >
                                   <span v-html="dispError(err.memberCd)"></span> 
                                </div>
                            </td>
                        </template>
                        
                    </tr>
                    <tr>
                        <th class="text-left pl-3">支部<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-select
                                v-model="inp.shibuCd"
                                class="width-200"
                                height="32"
                                :error="isError(err.shibuCd)"
                                outlined
                                solo
                                dense
                                hide-details=""
                                :items="shibulist"
                                item-text="shibu_name"
                                item-value="shibu_cd"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.shibuCd)"
                            >
                                <span v-html="dispError(err.shibuCd)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">会員（会社）名称<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                :error="isError(err.memberName)"
                                outlined
                                hide-details=""
                                dense
                                class="tokui-name"
                                v-model="inp.memberName"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.memberName)"
                            >
                                <span v-html="dispError(err.memberName)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">会員（会社）カナ<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                :error="isError(err.memberNameKana)"
                                outlined
                                hide-details=""
                                dense
                                class="tokui-kana"
                                v-model="inp.memberNameKana"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.memberNameKana)"
                            >
                                <span v-html="dispError(err.memberNameKana)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">代表者名<span class="required">(必須)</span></th>
                        <td colspan="2">
                            <v-text-field
                                solo
                                maxlength="20"
                                outlined
                                :error="isError(err.daihyoName)"
                                hide-details=""
                                dense
                                class="tantou-name"
                                v-model="inp.daihyoName"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.daihyoName)"
                            >
                                <span v-html="dispError(err.daihyoName)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">会員法人番号</th>
                        <td colspan="2">
                            <v-text-field
                                v-model="inp.memberNo"
                                solo
                                maxlength="13"
                                outlined
                                hide-details=""
                                dense
                                class="width-200"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">郵便番号<span class="required">(必須)</span></th>
                        <td colspan="3">
                            <div class="d-inline-flex align-center">
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="8"
                                    outlined
                                    hide-details="auto"
                                    :error="isError(err.post)"
                                    dense
                                    class="post"
                                    v-model="inp.post"
                                    @blur="blurPost"
                                />
                                <v-btn
                                    class="my-2 ml-8"
                                    color="primary"
                                    @click="getAddress"
                                    >郵便番号から住所に変換</v-btn
                                >
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.post)"
                            >
                                <span v-html="dispError(err.post)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">市区町村CD<span class="required">(必須)</span></th>
                        <td>
                            <v-text-field
                                solo
                                maxlength="6"
                                outlined
                                :error="isError(err.areaCd)"
                                hide-details=""
                                dense
                                class="width-200"
                                v-model="inp.areaCd"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.areaCd)"
                            >
                                <span v-html="dispError(err.areaCd)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3" rowspan="3">住所</th>
                        <th>都道府県<span class="required">(必須)</span></th>
                        <td colspan="4">
                            <v-select
                                v-model="inp.tdfkName"
                                class="width-200"
                                height="32"
                                outlined
                                :error="isError(err.tdfkName)"
                                solo
                                dense
                                hide-details=""
                                :items="tdfklist"
                                item-text="tdfk_name"
                                item-value="tdfk_name"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.tdfkName)"
                            >
                                <span v-html="dispError(err.tdfkName)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>市区町村<span class="required">(必須)</span></th>
                        <td colspan="4">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                :error="isError(err.address1)"
                                hide-details=""
                                dense
                                class="address"
                                v-model="inp.address1"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.address1)"
                            >
                                <span v-html="dispError(err.address1)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>丁目番地</th>
                        <td colspan="4">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="address"
                                v-model="inp.address2"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">電話番号／FAX番号</th>
                        <td colspan="5">
                            <div class="d-inline-flex align-center">
                                <span class="mr-2">電話番号</span>
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="14"
                                    :error="isError(err.tel)"
                                    outlined
                                    hide-details=""
                                    dense
                                    class="tel"
                                    v-model="inp.tel"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.tel)"
                                >
                                    <span v-html="dispError(err.tel)"></span>
                                </div>
                                <span class="ml-4 mr-2">FAX番号</span>
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="14"
                                    :error="isError(err.fax)"
                                    outlined
                                    hide-details=""
                                    dense
                                    class="tel"
                                    v-model="inp.fax"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.fax)"
                                >
                                    <span v-html="dispError(err.fax)"></span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">担当者名</th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="tantou-name"
                                v-model="inp.tantouName"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">メールアドレス<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="60"
                                type="tel"
                                :error="isError(err.mail)"
                                outlined
                                hide-details=""
                                dense
                                class="mail"
                                v-model="inp.mail"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.mail)"
                            >
                                <span v-html="dispError(err.mail)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">ホームページ</th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="mail"
                                v-model="inp.homepage"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">メモ</th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                id="memo"
                                maxlength="200"
                                outline
                                placeholder=" "
                                hide-details="auto"
                                dense
                                type="text"
                                name="memo-input"
                                v-model="inp.memo"
                                autocomplete="off"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">パスワード</th>
                        <td colspan="5">
                            <div style="width:150px">
					<v-text-field 
                    autocomplete="new-password"
					:append-icon="showPass?'mdi-eye':'mdi-eye-off'"
					v-model="inp.password" 
					:type="showPass?'tel':'password'"
					maxlength="15"
                    name="new-pass"
					@click:append="showPass=!showPass"
                    hide-details="auto" dense outlined
                    ></v-text-field>
                    </div>

                        </td>
                    </tr>
                </table>
                <div class="text-left">
                </div>
            </div>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
.member-edit-table {
    table-layout: fixed;
    width: 950px;
    border: 1px solid #ccc;

    th {
        border: 1px solid #ccc;
        text-align: left;
        padding-left: 5px;
        background-color: #555;
        color: white;
        font-weight: normal;
        padding: 5px 5px 5px 5px;
    }
    td {
        border: 1px solid #ccc;
        padding: 5px 5px 5px 5px;
    }
    td .dspfld {
        height: 34px;
        margin: 5px 0;
        border: 1px solid #aaa;
        background-color: #eee;
        font-size: 16px;
    }
    .v-input {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 6px;
    }
    .width-200 {
        width: 200px;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant"; 
import InputDateFld from "../components/InputDateFld";

export default {
    components: { InputDateFld },
    data() {
        return {
            mode: "i", //i:新規 u:更新
            saveLoading: false,
            memberId:'',
            showPass: false,
            autoFalse : false,
            //入力v-model
            inp:{
                status:'',
				nyukaiBi: null,
				taikaiBi: null,
                memberCd:'',
				memberKbn: '',
				shubetsuKbn: '',
				shibuCd: '',
                memberName:'',
                memberNameKana:'',
                daihyoName:'',
				memberNo: '',
                post:'',
                areaCd:'',
				tdfkName: '',
                address1:'',
                address2:'',
                tel:'',
                fax:'',
				tantouName: '',
                mail:'',
				homepage: '',
                memo:'',
                password:'',
            },

            //エラー表示用
            err: ({} = {
                status:[],
				nyukaiBi:[],
                taikaiBi: [],
                memberCd:[],
				memberKbn: [],
				shubetsuKbn: [],
				shibuCd: [],
                memberName:[],
                memberNameKana:[],
                daihyoName:[],
				memberNo: [],
                post:[],
                areaCd:[],
				tdfkName: [],
                address1:[],
                address2:[],
                tel:[],
                fax:[],
				tantouName: [],
                mail:[],
				homepage: [],
                memo:[],
                password:'',
            }),
            //プルダウンリスト
            satuslist:[
                { val: 0, name: "通常" },
                { val: 1, name: "停止" },
                { val: 2, name: "保留" },
                { val: 9, name: "退会" },
            ],
            kbnlist:[
                { val: 1, name: "正会員" },
                { val: 2, name: "賛助会員" },
            ],
            shubetsulist:[
                { val: 0, name: "リサイクル業者" },
                // { val: 1, name: "工事事業者" },
                // { val: 2, name: "その他事業者" },
            ],
            shibulist:[],
            tdfklist:[],

        };
    },
    mounted(){
        if(this.$store.state.memberId !==''){
        //if(this.$route.params.mode == 'u'){
            this.mode = 'u';
            this.memberId = this.$store.state.memberId;
            this.getMember();
        }else{
    		this.mode = 'i';
            this.generatePass();
        }
        this.getLists();
    },
    computed:{
        taikaiFlg(){
            if(this.inp.status !== 9){
                return true;
            }
            return false;
        }
    },
    methods: {
        /**
         * 会員番号のチェック
         */
        async changeMemberCd() {
            this.err.memberCd = [];
            if(this.inp.memberCd == '' ){
                return;
            }
            if (this.inp.memberCd.length < 5) {
                this.err.memberCd.push("半角数値５桁でご入力下さい");
                return;
            }

            if (isNaN(this.inp.memberCd)) {
                this.err.memberCd.push("半角数値でご入力下さい");
                return;
            }

            const firstChar = this.inp.memberCd.substr(0, 1);
            if (this.inp.memberKbn == 1) {
                if (firstChar !== '1') {
                    this.err.memberCd.push("正会員の会員番号は「1」から始まるようにしてください");
                    return;
                }
            } else if (this.inp.memberKbn == 2) {
                if (firstChar !== '2') {
                    this.err.memberCd.push("賛助会員の会員番号は「2」から始まるようにしてください");
                    return;
                }
            }
            
            const result = await this.axios.post('/check-membercd', { member_cd: this.inp.memberCd })
            if (result.data.resultCode !== CONSTANT.RETURN_NORMAL) {
                this.err.memberCd = [];
                this.err.memberCd.push(result.data.resultData.message + ".");
            }
            
        },
        async getLists(){
            await this.axios.get("/member-get-list").then((res) => {
                if(res.data.resultCode == CONSTANT.RETURN_NORMAL ){
                    this.shibulist = res.data.resultData.shibulist;
                    this.tdfklist = res.data.resultData.tdfklist;
                }
            });
        },
        async generatePass(){
            await this.axios.get("/pass-generate").then((res) => {
                this.inp.password = res.data.resultData.password;
            });
        },
        async getMember(){
            this.axios.get(`/member/${this.memberId}`).then((res) => {
                if(res.data.resultCode!=CONSTANT.RETURN_NORMAL){
                    return ;
                }
                let datalist = res.data.resultData.datalist;
                this.inp.status = datalist.status;
                this.inp.nyukaiBi = datalist.nyukai_bi;
                this.inp.taikaiBi = datalist.taikai_bi;
                this.inp.memberCd = datalist.member_cd;
                this.inp.memberKbn = datalist.member_kbn;
                this.inp.shubetsuKbn = datalist.shubetsu_kbn;
                this.inp.shibuCd = datalist.shibu_cd;
                this.inp.memberName = datalist.member_name;
                this.inp.memberNameKana = datalist.member_name_kana;
                this.inp.daihyoName = datalist.daihyo_name;
                this.inp.memberNo = datalist.member_no;
                this.inp.post = datalist.post;
                this.inp.areaCd = datalist.area_cd;
                this.inp.tdfkName = datalist.tdfk_name;
                this.inp.address1 = datalist.address1;
                this.inp.address2 = datalist.address2;
                this.inp.tel = datalist.tel;
                this.inp.fax = datalist.fax;
                this.inp.tantouName = datalist.tantou_name;
                this.inp.mail = datalist.mail;
                this.inp.homepage = datalist.homepage;
                this.inp.memo = datalist.memo;
                this.inp.password = datalist.password;
                console.log("data", this.inp);
            }); 
        },
        /**
         * 郵便番号庵フォーカス
         * ハイフンを付与する
         */
        blurPost() {
            if (this.isPostCode(this.inp.post)) {
                if (this.inp.post.length === 7) {
                    this.inp.post =
                        this.inp.post.slice(0, 3) + "-" + this.inp.post.slice(3);
                }
            }
        },
        /**
         * 郵便番号
         */
        getAddress(){
            //エラーのクリア
            this.err.post = [];
            if(this.inp.post===''){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001, "郵便番号")];
                return;
            }

            if( !this.isPostCode(this.inp.post)){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E005, "郵便番号の形式")];
                return ;
            }
            this.axios.post(`post-address`, { post: this.inp.post }).then((res) => {
                    if (res.data.resultData.zipinfo) {
                        this.inp.tdfkName = res.data.resultData.zipinfo.tdfk_name;
                        this.inp.address1 = res.data.resultData.zipinfo.address1;
                        this.inp.areaCd = res.data.resultData.zipinfo.city_code;
                    }else{
                        this.err.post = [MESSAGE.formatByArr(MESSAGE.E009, "郵便番号","住所")];
                    }
            });
        },
        /**
         * 入力チェック
         */
        async inputCheck(){

            // -- ステータス
            if(this.inp.status === ''){
                this.err.status = [MESSAGE.formatByArr(MESSAGE.E002,'ステータス')];
            }

            // -- 会員番号
            if (this.inp.memberCd === '') {
                this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E001, '会員CD')];
            } else {
                this.err.memberCd = [];
                if (this.mode !== 'u') {
                    await this.changeMemberCd();
                }
            }

            // -- 入会日
            if(!this.inp.nyukaiBi){
                this.err.nyukaiBi = [MESSAGE.formatByArr(MESSAGE.E002,'入会日')];
            }
            // -- 退会日
            if(this.inp.status === 9 && !this.inp.taikaiBi){
                this.err.taikaiBi = [MESSAGE.formatByArr(MESSAGE.E002,'退会日')];
            }
            // -- 会員区分
            if(this.inp.memberKbn === ''){
                this.err.memberKbn = [MESSAGE.formatByArr(MESSAGE.E002,'会員区分')];
            }
            // -- 種別
            if(this.inp.shubetsuKbn === ''){
                this.err.shubetsuKbn = [MESSAGE.formatByArr(MESSAGE.E002,'種別')];
            }
            // -- 支部
            if(!this.inp.shibuCd){
                this.err.shibuCd = [MESSAGE.formatByArr(MESSAGE.E002,'支部')];
            }

            // -- 会員（会社）名称
            if (!this.inp.memberName) {
                this.err.memberName = [MESSAGE.formatByArr(MESSAGE.E001,'会員（会社）名称')];
            }
            // -- 会員（会社）カナ
            if (!this.inp.memberNameKana) {
                this.err.memberNameKana = [MESSAGE.formatByArr(MESSAGE.E001,'会員（会社）カナ')];
            } 
            // -- 代表者名
            /*
            if (!this.inp.daihyoName) {
                this.err.daihyoName = [MESSAGE.formatByArr(MESSAGE.E001,'代表者名')];
            }
            */ 

            // -- 郵便番号
            if (!this.inp.post) {
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001,'郵便番号')];
            }else{
                if (!this.isPostCode(this.inp.post)) {
                    this.err.post = [MESSAGE.formatByArr(MESSAGE.E005,'郵便番号')];
                }
            }
            // -- 市区町村CD   
            if (!this.inp.areaCd) {
                this.err.areaCd = [MESSAGE.formatByArr(MESSAGE.E001,'市区町村CD')];
            } 
            // -- 都道府県
            if (!this.inp.tdfkName) {
                this.err.tdfkName = [MESSAGE.formatByArr(MESSAGE.E001,'都道府県')];
            } 
            // -- 市区町村
            if (!this.inp.address1) {
                this.err.address1 = [MESSAGE.formatByArr(MESSAGE.E001,'市区町村')];
            } 

            //*** 電話番号
            if (this.inp.tel && !this.isTelNo(this.inp.tel)) {
                this.err.tel = [MESSAGE.formatByArr(MESSAGE.E005, "電話番号")];
            }
            //*** FAX番号
            if (this.inp.fax !== "" && !this.isTelNo(this.inp.fax)) {
                this.err.fax = [MESSAGE.formatByArr(MESSAGE.E005, "FAX番号")];
            }
            //*** メールアドレス
            if (!this.inp.mail) {
                this.err.mail = [MESSAGE.formatByArr(MESSAGE.E001,'メールアドレス')];
            }else{
                if (this.inp.mail !== "" && !this.isMailaddress(this.inp.mail)) {
                    this.err.mail = [MESSAGE.formatByArr(MESSAGE.E005, "メールドレスの形式")];
                }
                if(await this.isMail()){
                    this.err.mail = [MESSAGE.formatByArr(MESSAGE.E010, "このメールドレス")];
                }
            }
            console.log("chk", this.err);
            for (let key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        async isMail(){
            let mailData = {member_id: this.memberId, mail: this.inp.mail};
            return await this.axios.post(`/member-is-mail`, mailData).then((res) => {
                if (res.data.resultCode == 1) {
                    return true;
                }
                return false;
            });
        },
        /**
         * 保存ボタンクリック
         */
        async clickSave(){

            //error clear
            this.clearError();

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }
            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }
            if(this.mode==='i'){
                // store Api
                await this.axios.post("/member", this.inp).then((res) => {
                    this.inp.memberCd = res.data.resultData.memberCd;
                    this.$swal({
                        title: "登録が完了しました",
                        text: "会員CD: "+this.inp.memberCd,
                        icon: "success",
                        allowOutsideClick: false,
                    }).then(() => {
                        this.$router.push({name: "MemberList"});
                    });
                });
            }else if(this.mode==='u'){
                await this.axios.put(`/member/${this.memberId}`,this.inp).then((res)=>{
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,this.inp.memberCd));
                    this.$router.push({name: "MemberList"});
                });
            }

        },
        dlgCallBack(){

        },
        async clickBack(){
            if(this.mode =='U'){
                this.$router.go(-1);
            }else{
                this.$router.push({name: "Top"});
            }
        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
    }
}
</script>