<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-account-multiple</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員承認
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-stepper v-model="valStep" class="ma-5">
            <v-stepper-header>
                    <v-stepper-step :complete="valStep > 1" step="1" >
                        申込内容
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step :complete="valStep > 2" step="2" >
                        メール送信 
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step  :complete="compStep3" :step="compStep" >
                        完了 
                    </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <table class="member-table mb-1">
                        <col width="20%" />
                        <col width="auto" />
                        <tr>
                            <th>会員CD</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tokui-cd"
                                    elevation="0"
                                >
                                    {{ inp.memberCd }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>会員区分</th>
                            <td>
                                <v-sheet
                                        outlined
                                        width="500"
                                        class="rounded pa-2 dspfld member-kbn"
                                        elevation="0"
                                    >
                                        {{ inp.memberKbn }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>パスワード</th>
                            <td>
                                <div class="d-inline-flex align-center">
                                    <v-text-field
                                        solo
                                        :type="showPassword ? 'text' : 'password'"
                                        maxlength="10"
                                        :error="isError(err.password)"
                                        outlined
                                        hide-details=""
                                        :append-icon="showPassword ?'mdi-eye': 'mdi-eye-off'" 
                                        dense
                                        class="my-2 tel"
                                        v-model="inp.password"
                                        @click:append="showPassword =! showPassword"
                                    />
                                    <v-btn class="ma-2" @click="getPass">再取得</v-btn>
                                </div>
                                <div
                                    class="d-flex controll-error ml-2"
                                    v-show="isError(err.password)"
                                >
                                    <span v-html="dispError(err.password)"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">会社名称／会社カナ</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tokui-name"
                                    elevation="0"
                                >
                                    {{ inp.corpName }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tokui-name"
                                    elevation="0"
                                >
                                    {{ inp.corpKana }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>会員法人番号</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld houjin-no"
                                    elevation="0"
                                >
                                    {{ inp.houjinNo }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>住所</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld address"
                                    elevation="0"
                                >
                                    {{ inp.address }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>支部</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld branch"
                                    elevation="0"
                                >
                                    {{ inp.branch }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">電話番号／FAX番号</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tel"
                                    elevation="0"
                                >
                                    {{ inp.tel }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tel"
                                    elevation="0"
                                >
                                    {{ inp.fax }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld mail"
                                    elevation="0"
                                >
                                    {{ inp.mail }}
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <th>担当者名</th>
                            <td>
                                <v-sheet
                                    outlined
                                    width="500"
                                    class="rounded pa-2 dspfld tantou-name"
                                    elevation="0"
                                >
                                    {{ inp.tantouName }}
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                    <v-btn class="btn-close ma-3" @click="clickCancel">戻る</v-btn>

                    <v-btn class="btn-edit ma-3" width="140" @click="clickStep1(0)">承認する</v-btn>
                    <v-btn class="btn-delete ma-3" width="140" @click="clickStep1(1)">否認する</v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <div>
                        <table class="member-table mb-8">
                            <col width="20%" />
                            <col width="auto" />
                            <tr>
                                <th>送信元（FROM）</th>
                                <td>
                                    <v-sheet
                                        outlined
                                        width="500"
                                        class="rounded pa-2 dspfld mail"
                                        elevation="0"
                                    >
                                    {{ inp.mailFrom }}
                                    </v-sheet>
                                </td>
                            </tr>
                            <tr>
                                <th>送信先（TO）</th>
                                <td>
                                    <v-text-field
                                        :solo="!mailToFlg"
                                        outlined
                                        :disabled="mailToFlg"
                                        maxlength="60"
                                        type="tel"
                                        :error="isError(err.mail)"
                                        hide-details=""
                                        dense
                                        class="mail pb-1"
                                        :class="{'dspfldMailTo' : mailToFlg}"
                                        elevation="0"
                                        v-model="inp.mail"
                                    >
                                    </v-text-field>
                                    <div
                                        class="controll-error ml-3"
                                        v-show="isError(err.mail)"
                                    >
                                        <span v-html="dispError(err.mail)"></span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>タイトル</th>
                                <td>
                                    <div>
                                        <v-text-field
                                            solo
                                            maxlength="40"
                                            type="tel"
                                            outlined
                                            :error="isError(err.mailSubject)"
                                            hide-details="auto"
                                            dense
                                            class="mail-subject"
                                            v-model="inp.mailSubject"
                                        />
                                        <div
                                            class="controll-error ml-2"
                                            v-show="isError(err.mailSubject)"
                                        >
                                            <span
                                                v-html="dispError(err.mailSubject)"
                                            ></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>本文</th>
                                <td>
                                    <div>
                                        <v-textarea
                                            solo
                                            maxlength="1000"
                                            outlined
                                            :error="isError(err.mailContent)"
                                            hide-details="auto"
                                            dense
                                            v-model="inp.mailContent"
                                        />
                                        <div
                                            class="controll-error ml-2"
                                            v-show="isError(err.mailContent)"
                                        >
                                            <span
                                                v-html="dispError(err.mailContent)"
                                            ></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="d-flex justify-space-around">
                        <v-checkbox v-model="compChk" :label="'上記のメールを送信する'"></v-checkbox>
                    </div>
                    <div class="d-flex justify-space-around">
                        <div>
                            <v-btn class="btn-close ma-2" @click="clickBackStep1">戻る</v-btn>
                            <v-btn :class="compChk ? 'btn-edit': ''" :disabled="!compChk" class="ma-2" width="140"  @click="clickStep2"> 次へ </v-btn>
                        </div>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-sheet class="mx-auto my-15" height="150" width="400" color="blue lighten-5" style="border: solid 1px #ccc !important;" outlined>
                        <div class="ma-5 text-center text-h6 font-weight-bold">完了しました</div>
                        <v-divider class="mx-3" />
                        <div class="text-center">
                            <v-btn class="mt-5 btn-close" @click="clickCancel">一覧へもどる</v-btn>
                        </div>
                    </v-sheet>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>
<style lang="scss" scoped>
.member-table {
    table-layout: fixed;
    width: 950px;
    border: 1px solid #ccc;

    th {
        border: 1px solid #ccc;
        text-align: left;
        padding-left: 5px;
        background-color: #555;
        color: white;
        font-weight: normal;
        padding: 5px 5px 5px 5px;
    }
	td {
		border: 1px solid #ccc;
        padding: 5px 5px 5px 5px;
	}
    td .dspfld {
        height: 34px;
        margin: 5px 0;
        border: 1px solid #aaa;
        background-color: #eee;
        font-size: 16px;
    }
    .dspfldMailTo  {
        .v-input__slot{
            background-color:#eee !important;
            input{
                color:rgba(0, 0, 0, 0.87) !important;
            }
        }
    }
	.v-input{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
        padding-top: 6px;
	}
    .v-textarea{
        padding-bottom: 6px;
    }
    .v-sheet{
		font-size: 16px;
        line-height: 0;
        display: flex;
        align-items: center;
	}
}
.mailTo{
    width:500px !important;
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld.vue";
export default {
    components: { InputDateFld },
    computed:{
        compStep(){
            if(this.inp.status==2){
                return '3';
            }
            return '2'
        },
        //送信先が使用不可のメールだったら再入力させる。
        mailToFlg(){
            if(this.err.mail == ''){
                return true;
            }else{
                return false;
            }
        },
    },
    data() {
        return {
            steps: [
                { title: "申込内容", step: 1 },
                { title: "メール送信", step: 2 },
                { title: "完了", step: 3 },
            ],

            valStep: 1,

            saveLoading: false,
            showPassword : false,
            compStep1:true,
            compStep2:false,
            compStep3:false,

            approvalNo: null,

            mailCont:{},
            content:'',
            compChk: false,

            //入力v-model
            inp: {
                memberCd: 'T001',
                memberKbn: '秋田',
                password: '#TestTest',
                corpName: '株式会社能代',
                corpKana: 'カブシキガイシャノシロ',
                houjinNo: '011000000',
                address: '〒016-0000 秋田県能代市00-00',
                branch: '10',
                tel: '03-000-0000',
                fax: '03-000-0000',
                mail: 'test-okinawa@sabira.jp',
                tantouName: '沖縄太郎',

                mailFrom: 'info@sabira.jp',
            },

            //エラー表示用
            err: ({} = {
                memberCd: '',
                memberKbn: '',
                password: '',
                corpName: '',
                corpKana: '',
                houjinNo: '',
                address: '',
                branch: '',
                tel: '',
                fax: '',
                mail: '',
                tantouName: '',

                mailFrom: '',
            }),
        };
    },
    mounted() {
        this.memberId = this.$route.params.memberId;
        // 会員情報の取得
        this.getMember();
    },
    methods: {
        /**
         * パスワード自動生成
         */
        clickGetPassword(){
            var letters = 'abcdefghijklmnopqrstuvwxyz';
            var numbers = '123456789';
            var symbols = '!#&';
            var passStr  = '';

            passStr = this.passGenerate(letters, 5) + this.passGenerate(letters.toUpperCase(), 2) + this.passGenerate(numbers, 2) + this.passGenerate(symbols, 1);

            //パスワードのシャッフル処理
            var a = passStr.split(""),
                n = a.length;

            for(var i = n - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var tmp = a[i];
                a[i] = a[j];
                a[j] = tmp;
            }
            this.inp.password =  a.join("");
            
        },
        /**
         * パスワードをランダム生成する関数
         */
        passGenerate(val, len){
            var pass = '';
            for(var i =0  ; i < len ; i++){
                pass += val.charAt(Math.floor(Math.random() * val.length));
            }
            return pass; 
        },

        /**
         * 一覧へもどるボタンクリック
         */
        clickCancel(){
            // this.$router.go(-1);
            this.valStep = 1;
        },
        clickBackStep1(){
                this.valStep = 1;
        },
        /**
         * 送信メール内容作成
         */
        convMailContent(){
            this.inp.mailFrom = this.mailCont.mail_from;
            this.inp.mailSubject = this.mailCont.subject;
            this.inp.mailContent= this.mailCont.content;
            this.inp.mailContent = this.inp.mailContent.replace('%corp_name%', this.inp.corpName);
            this.inp.mailContent = this.inp.mailContent.replace('%corp_kana%', this.inp.corpKana);
            this.inp.mailContent = this.inp.mailContent.replace('%member_name%', this.inp.member_name);
            this.inp.mailContent = this.inp.mailContent.replace('%member_kana%', this.inp.member_kana);
            this.inp.mailContent = this.inp.mailContent.replace('%post%', this.inp.post);
            this.inp.mailContent = this.inp.mailContent.replace('%fax%', this.inp.fax);
            this.inp.mailContent = this.inp.mailContent.replace('%mail%', this.inp.mail);
            this.inp.mailContent = this.inp.mailContent.replace('%member_cd%', this.inp.memberCd);
            this.inp.mailContent = this.inp.mailContent.replace('%password%', this.inp.password);
        },
        /**
         * Step1
         */
        async clickStep1(no){
            this.approvalNo = no;
            this.valStep = 2;
        },
        /**
         * ステップ２
         */
        async clickStep2(){
            // this.inp.mailSendFlg = true;
            this.clearError();
            this.valStep = 3;

            // // 入力チェック
            // if(await this.inputCheckStep2()){
            //     this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
            //     return ;
            // }

            // // 更新処理
            // if(await this.approvalMember()){
            //     this.valStep = 3; 
            //     this.compStep3 = true;
            // }

        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        /**
         * 会員重複チェック
         */
        async isMemberCd(){

              return  await this.axios.get(`/api/member/is_member_cd/${this.inp.memberCd}`).then((res)=>{
                            this.saveLoading = null;
                            if(res.data.isMember){
                                // true:同一の会員CDは存在している
                                return true;
                            }
                            return false;
                });
        },
    },
};
</script>