<template>
    <v-container fluid class="mt-0 pa-0">

        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-home</v-icon>
            ダッシュボード  
        </v-sheet>
        <v-card class="mx-5">
            <v-card-title>お知らせ</v-card-title>
            <v-card-text>
                <div class="d-inline-flex">
                    <p>現在「お知らせ」はありません</p>
                </div>
            </v-card-text>
        </v-card>
        <v-card class="ma-5">
            <v-row>
                <v-col class="col-6">
                    <v-card class="mx-5" flat>
                    <v-card-title>最近更新されたプラント</v-card-title>
                    <v-card-text>
                        <v-simple-table class="plant-tbl" style="width:100%">
                            <col width="150">
                            <tr>
                                <th>更新日時</th>
                                <th>プラント名</th>
                            </tr>
                            <tr v-for="(item, index) in updateList" :key="index">
                                <td class="d-flex justify-center">{{item.update_at}}</td>
                                <td>{{item.member_name}}　{{item.plant_name}}</td>
                            </tr>
                        </v-simple-table>
                    </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
import { CONSTANT } from '../constant';
export default {
    name: "Member",
    components: {},
    data() {
        return {
            kadoCnt:0,
            newCnt:0,
            systemInfo:{},
            statusFlg: null,
            updateList:[],
        };
    },
    mounted() {
        this.setOverlay(false);
        this.getDashbord();
    },
    methods: {
        /**
         * お知らせ一覧を取得
         */
        getDashbord() {
            this.axios.get("/admin-dashbord-info").then((res) => {
                if(res.data.result==0){

                    this.kadoCnt = res.data.kadoCnt;
                    this.newCnt  = res.data.newCnt;
                    this.systemInfo = res.data.systemInfo;

                    // 停止設定時間内なら、「停止」を表示する。
                    var dateNow = moment(new Date).format("YYYY-MM-DD HH:mm");
                    var getDateStart = moment(this.systemInfo.mainte_start_date).format("YYYY-MM-DD HH:mm");
                    var getDateEnd = moment(this.systemInfo.mainte_end_date).format("YYYY-MM-DD HH:mm");
                    
                    if(this.systemInfo.system_status == 1 || (this.systemInfo.system_status == 2 && getDateStart <= dateNow && dateNow < getDateEnd)){
                        return this.statusFlg = false;
                    }
                    return this.statusFlg = true;
                }
            });
      
            this.updateList=[];
            this.axios.get('/updates-plant-list').then((res)=>{
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.updateList = res.data.resultData.plant_list;
                }
            });
        
        
        },
    },
};
</script>
