<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-database</v-icon>
            マスタ管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                   都道府県・支部マスタ管理 
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
		<v-row>
			<v-col cols="5" class="mx-10">
				<div class="text-h6">都道府県マスタ</div>
				<v-data-table
					:headers="tdfkHeaders"
					:items="tdfkMst"
					dense
					disable-pagination
					hide-default-footer
					height="400px"
					fixed-header
					class="master-list-table"
				>
				<template v-slot:item.actions="{item}">
					<v-icon  class="mr-2"
					@click="editTdfk(item)"
					>mdi-pencil</v-icon>
				</template>
				</v-data-table>

			</v-col>
			<v-col cols="5">
				<div class="text-h6">支部マスタ</div>
				<v-data-table
					:headers="shibuHeaders"
					:items="shibuMst"
					dense
					disable-pagination
					hide-default-footer
					height="400px"
					fixed-header
					class="master-list-table"
				>
				<template v-slot:item.actions="{item}">
					<v-icon class="mr-2"
					@click="editShibu(item)"
					>mdi-pencil</v-icon>
				</template>
				</v-data-table>
			</v-col>
		</v-row>

		<!-- 都道府県編集ダイアログ -->
		<v-dialog v-model="dlgTdfk" persistent max-width="400px">
			<v-card>
			    <v-toolbar height="40" dark>
					<v-toolbar-title>都道府県マスタ更新</v-toolbar-title>
					<v-spacer />
			    </v-toolbar>
			    <v-container>
				<v-card>

					<v-simple-table class="dlg-tdfk-edit-table">
						<colgroup>
						<col style="width:30%">
						</colgroup>
						<tr>
							<th>都道府県CD</th>
							<td>{{dlgTdfkData.tdfk_cd}}</td>
						</tr>
						<tr>
							<th>都道府県名</th>
							<td>{{dlgTdfkData.tdfk_name}}</td>
						</tr>
						<tr>
							<th>支部CD</th>
							<td>
                            <v-select
                                v-model="dlgTdfkData.shibu_cd"
                                class="width-200"
                                height="32"
                                :error="isError(errDlgTdfkData.shibu_cd)"
                                outlined
                                solo
                                dense
                                hide-details=""
                                :items="shibuMst"
                                item-text="shibu_name"
                                item-value="shibu_cd"
                            />

								<div
									class="controll-error ml-2"
									v-show="isError(errDlgTdfkData.shibu_cd)"
								>
									<span v-html="dispError(errDlgTdfkData.shibu_cd)"></span>
								</div>

							</td>
						</tr>
						<tr>
							<th>ソート順</th>
							<td>
								<v-text-field  type="number" @keypress="inputLimit(dlgTdfkData.sortno,2,$event)" style="width:50px" :error="isError(errDlgTdfkData.sortno)" hide-details="auto" dense outlined v-model="dlgTdfkData.sortno" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgTdfkData.sortno)"
								>
									<span v-html="dispError(errDlgTdfkData.sortno)"></span>
								</div>
							</td>
						</tr>
						<tr>
							<th>緯度（lat）</th>
							<td>
								<v-text-field :error="isError(errDlgTdfkData.lat)" style="width:120px" hide-details="auto" dense outlined v-model="dlgTdfkData.lat" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgTdfkData.lat)"
								>
									<span v-html="dispError(errDlgTdfkData.lat)"></span>
								</div>
							</td>
						</tr>
						<tr>
							<th>経度（lng）</th>
							<td>
								<v-text-field :error="isError(errDlgTdfkData.lng)" style="width:120px" hide-details="auto" dense outlined v-model="dlgTdfkData.lng" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgTdfkData.lng)"
								>
									<span v-html="dispError(errDlgTdfkData.lng)"></span>
								</div>
								</td>
						</tr>
						<tr>
							<th>マップ拡大率</th>
							<td>

								<div class="d-flex d-inline-flex align-center">
								<div class="text-caption mr-2">縮小</div>
								<div style="width:120px"><v-slider dense hide-details="auto"  min="5" max="15" v-model="dlgTdfkData.scale" step="1"></v-slider></div>
								<div class="text-subtitle-1">拡大</div>
								<div class="ml-3">値：{{dlgTdfkData.scale}}</div>
								</div>

							</td>
						</tr>
					</v-simple-table>
					<div class="d-flex d-inline-flex">
						<v-btn class="btn-edit ma-3" small @click="saveTdfk">保存</v-btn>
						<v-btn class="btn-close ma-3" small @click="closeDlgTdfk">閉じる</v-btn>
					</div>
				</v-card>
				</v-container>
			</v-card>
		</v-dialog>


		<!-- 支部マスタ編集ダイアログ -->
		<v-dialog v-model="dlgShibu" persistent max-width="400px">
			<v-card>
			    <v-toolbar height="40" dark>
					<v-toolbar-title>支部マスタ更新</v-toolbar-title>
					<v-spacer />
			    </v-toolbar>
			    <v-container>
				<v-card>
					<v-simple-table class="dlg-tdfk-edit-table">
						<colgroup>
						<col style="width:30%">
						</colgroup>
						<tr>
							<th>支部CD</th>
							<td>{{dlgShibuData.shibu_cd}}</td>
						</tr>
						<tr>
							<th>支部名</th>
							<td>
								<v-text-field :error="isError(errDlgShibuData.shibu_name)" style="width:120px" hide-details="auto" dense outlined v-model="dlgShibuData.shibu_name" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgShibuData.shibu_name)"
								>
									<span v-html="dispError(errDlgShibuData.shibu_name)"></span>
								</div>

							</td>
						</tr>
						<tr>
							<th>ソート順</th>
							<td>
								<v-text-field  @keypress="inputLimit(dlgShibuData.sortno,2,$event)" type="number" :error="isError(errDlgShibuData.sortno)" style="width:50px" hide-details="auto" dense outlined v-model="dlgShibuData.sortno" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgShibuData.sortno)"
								>
									<span v-html="dispError(errDlgShibuData.sortno)"></span>
								</div>
							</td>
						</tr>
						<tr>
							<th>緯度（lat）</th>
							<td>
								<v-text-field :error="isError(errDlgShibuData.lat)" style="width:120px" hide-details="auto" dense outlined v-model="dlgShibuData.lat" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgShibuData.lat)"
								>
									<span v-html="dispError(errDlgShibuData.lat)"></span>
								</div>
							</td>
						</tr>
						<tr>
							<th>経度（lng）</th>
							<td>
								<v-text-field :error="isError(errDlgShibuData.lng)" style="width:120px" hide-details="auto" dense outlined v-model="dlgShibuData.lng" />
								<div
									class="controll-error ml-2"
									v-show="isError(errDlgShibuData.lng)"
								>
									<span v-html="dispError(errDlgShibuData.lng)"></span>
								</div>
								</td>
						</tr>
						<tr>
							<th>マップ拡大率</th>
							<td>
								<div class="d-flex d-inline-flex align-center">
								<div class="text-caption mr-2">縮小</div>
								<div style="width:120px"><v-slider dense hide-details="auto"  min="5" max="15" v-model="dlgShibuData.scale" step="1"></v-slider></div>
								<div class="text-subtitle-1">拡大</div>
								<div class="ml-3">値：{{dlgShibuData.scale}}</div>
								</div>
							</td>
						</tr>
					</v-simple-table>
					<div class="d-flex d-inline-flex">
						<v-btn class="btn-edit ma-3" small @click="saveShibu">保存</v-btn>
						<v-btn class="btn-close ma-3" small @click="closeDlgShibu">閉じる</v-btn>
					</div>
				</v-card>
				</v-container>
			</v-card>
		</v-dialog>

	</v-container>
</template>
<style lang="scss">
.master-list-table{
		border: 1px solid black !important;
		border-radius: 0px;
}
.master-list-table{
	th{
		background-color: #ccc !important;
	}
}
.dlg-tdfk-edit-table{
	th{
		background-color: #ccc !important;
		font-weight: normal;
		font-size:14px;
		height: 40px !important;
		padding:5px;
	}
	td{
		padding:5px;
	}
	
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} 
input[type="number"] { 
  -moz-appearance:textfield; 
} 
</style>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
export default {
    data() {
        return {
			zoomLevel:[5,7,8,9,10],
            tdfkMst: [],
            shibuMst: [],
            dlgTdfk: false,
			dlgShibu:false,
			dlgTdfkData:{
				tdfk_cd:'',
				tdfk_name:'',
				shibu_cd:'',
				scale:'',
				sortno:'',
				lat:'',
				lng:''	
			},
			dlgShibuData:{
				shibu_cd:'',
				shibu_name:'',
				scale:'',
				sortno:'',
				lat:'',
				lng:''	
			},
			errDlgTdfkData:{
				tdfk_cd:'',
				tdfk_name:'',
				shibu_cd:'',
				scale:'',
				sortno:'',
				lat:'',
				lng:''	
			},
			errDlgShibuData:{
				shibu_cd:'',
				shibu_name:'',
				scale:'',
				sortno:'',
				lat:'',
				lng:''	
			},
            tdfkHeaders: [
                {
                    text: "都道府県CD",
                    sortable: false,
                    value: "tdfk_cd",
                },
                {
                    text: "都道府県名",
                    sortable: false,
                    value: "tdfk_name",
                },
                {
                    text: "支部CD",
                    sortable: false,
                    value: "shibu_cd",
                },
                {
                    text: "緯度",
                    sortable: false,
                    value: "lat",
                },
                {
                    text: "経度",
                    sortable: false,
                    value: "lng",
                },
                {
                    text: "編集",
                    sortable: false,
                    value: "actions",
                },
            ],
            shibuHeaders: [
                {
                    text: "支部CD",
                    sortable: false,
                    value: "shibu_cd",
                },
                {
                    text: "支部名",
                    sortable: false,
                    value: "shibu_name",
                },
                {
                    text: "緯度",
                    sortable: false,
                    value: "lat",
                },
                {
                    text: "経度",
                    sortable: false,
                    value: "lng",
                },
                {
                    text: "編集",
                    sortable: false,
                    value: "actions",
                },
            ],
			ruleSort:[v=>v.length<=2 || 'err'],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
		inputLimit(fld,maxLength,e){
			if(fld.length>=maxLength){
				e.preventDefault();
			}
			
		},

        async init() {
			await this.getTdfk();
			await this.getShibu();
        },
		/**
		 * 都道府県マスタの取得
		 */
		async getTdfk(){
            const res = await this.axios.get("master/tdfkmst");
            if (res.data.resultCode == CONSTANT.RETURN_NORMAL ) {
                this.tdfkMst = res.data.resultData.tdfk_mst;
            }

		},
		/**
		 * 支部マスタの取得
		 */
		async getShibu(){
            const res = await this.axios.get("master/shibumst");
            if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                this.shibuMst = res.data.resultData.shibu_mst;
            }
		},
		/**
		 * 都道府県編集ボタン
		 */
        editTdfk(item) {
            console.log(item);
			this.dlgTdfkData.tdfk_cd = item.tdfk_cd;
			this.dlgTdfkData.tdfk_name = item.tdfk_name;
			this.dlgTdfkData.shibu_cd = item.shibu_cd;
			this.dlgTdfkData.sortno = item.sortno;
			this.dlgTdfkData.scale = item.scale;
			this.dlgTdfkData.lat = item.lat;
			this.dlgTdfkData.lng = item.lng;
            this.dlgTdfk = true;
        },
		/**
		 * 支部編集ボタン
		 */
        editShibu(item) {
            console.log(item);
			this.dlgShibuData.shibu_cd = item.shibu_cd;
			this.dlgShibuData.shibu_name = item.shibu_name;
			this.dlgShibuData.scale = item.scale;
			this.dlgShibuData.sortno = item.sortno;
			this.dlgShibuData.lat = item.lat;
			this.dlgShibuData.lng = item.lng;
            this.dlgShibu = true;
        },
		/**
		 * 都道府県ダイアログの終了
		 */
        closeDlgTdfk() {
            this.dlgTdfk = false;
        },
		/**
		 * 支部ダイアログの終了
		 */
        closeDlgShibu() {
            this.dlgShibu = false;
        },
		async saveTdfk(){
			if(this.chkInputTdfk()){
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
			}
			const res = await this.axios.post('/master/update-tdfk-master',{tdfkMst:this.dlgTdfkData});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.tdfkMst = res.data.resultData.tdfk_mst;
				this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,this.dlgTdfkData.tdfk_name));
				this.dlgTdfk = false;
			}else{
                await this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E012);
				this.dlgTdfk = false;
			}
		},
		chkInputTdfk(){
			this.clearError();
			if(!this.dlgTdfkData.shibu_cd){
				this.errDlgTdfkData.shibu_cd = [MESSAGE.formatByArr(MESSAGE.E001,'支部名')];
			}
			if(!this.dlgTdfkData.sortno){
				this.errDlgTdfkData.sortno = [MESSAGE.formatByArr(MESSAGE.E001,'ソート順')];
			}
			if(!this.dlgTdfkData.lat){
				this.errDlgTdfkData.lat = [MESSAGE.formatByArr(MESSAGE.E001,'緯度')];
			}
			if(!this.dlgTdfkData.lng){
				this.errDlgTdfkData.lng = [MESSAGE.formatByArr(MESSAGE.E001,'経度')];
			}
			if(!this.dlgTdfkData.scale){
				this.errDlgTdfkData.scale = [MESSAGE.formatByArr(MESSAGE.E001,'マップ拡大率')];
			}
            for (let key in this.errDlgTdfkData) {
                if (this.errDlgTdfkData[key].length > 0) return true;
            }
            return false;
		},
		async saveShibu(){
			// 入力チェック
			if(this.chkInputShibu()){
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
			}
			const res = await this.axios.post('/master/update-shibu-master',{shibuMst:this.dlgShibuData});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.shibuMst = res.data.resultData.shibu_mst;
				this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,this.dlgShibuData.shibu_name));
				this.dlgShibu = false;
			}else{
                await this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E012);
				this.dlgShibu = false;
			}
		},
		/**
		 * 支部の入力チェック
		 */
		chkInputShibu(){
			this.clearError();
			if(!this.dlgShibuData.shibu_name){
				this.errDlgShibuData.shibu_name = [MESSAGE.formatByArr(MESSAGE.E001,'支部名')];
			}
			if(!this.dlgShibuData.sortno){
				this.errDlgShibuData.sortno = [MESSAGE.formatByArr(MESSAGE.E001,'ソート順')];
			}
			if(!this.dlgShibuData.lat){
				this.errDlgShibuData.lat = [MESSAGE.formatByArr(MESSAGE.E001,'緯度')];
			}
			if(!this.dlgShibuData.lng){
				this.errDlgShibuData.lng = [MESSAGE.formatByArr(MESSAGE.E001,'経度')];
			}
			if(!this.dlgShibuData.scale){
				this.errDlgShibuData.scale = [MESSAGE.formatByArr(MESSAGE.E001,'マップ拡大率')];
			}
            for (let key in this.errDlgShibuData) {
                if (this.errDlgShibuData[key].length > 0) return true;
            }
            return false;
		},

        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.errDlgTdfkData) {
                this.errDlgTdfkData[key] = [];
            }
            for (var key in this.errDlgShibuData) {
                this.errDlgShibuData[key] = [];
            }
        },

    },
};
</script>